export default {
  en: {
    errorCancelFailed: 'The reservation could not be cancelled.',
    errorCancelUnavailable:
      'Cancellation failed because the reservation has already been modified or cancelled.',
    pageTitle: 'Reservations'
  },
  ja: {
    errorCancelFailed: '予約キャンセルに失敗しました。',
    errorCancelUnavailable:
      '予約がすでに変更またはキャンセルされているためキャンセルに失敗しました。',
    pageTitle: '予約状況'
  }
}
