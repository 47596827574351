import dedent from 'dedent'

export default {
  en: {
    popupTitleSessionError: 'Session timeout',
    popupTitleVersionError: 'Please refresh the page',
    popupMessageSessionError: dedent`
      Your session has expired.
      Please login again.`,
    popupMessageVersionError: dedent`
      Thank you for using {serviceName}.
      Your current session has been interrupted due to an update of our reservation system.
      Please refresh the page.
      Changes made during the current session will not be saved.
      Please try again later.`
  },
  ja: {
    popupTitleSessionError: '警告',
    popupTitleVersionError: '画面の再読み込みのお願い',
    popupMessageSessionError: dedent`
      ログインの有効期限が切れました。
      再度ログインして下さい。`,
    popupMessageVersionError: dedent`
      いつも{serviceName}をご利用いただきありがとうございます。
      予約システムのアップデートに伴い、現在の操作が中断されました。
      画面の再読み込みをお願い致します。
      ※現在操作中の変更は保存されません。
      お手数ですが、再度操作をやり直していただくようお願い致します。`
  }
}
