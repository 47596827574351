export default {
  en: {
    pageTitle: 'Owner information change completed',
    textChangeCompleted: 'The change of owner information has been completed.',
    textMailSent:
      'We have sent an email to your registered e-mail address to confirm the owner information change.'
  },
  ja: {
    pageTitle: '飼主様情報変更完了',
    textChangeCompleted: '飼主様情報の変更が完了いたしました。',
    textMailSent:
      '登録したメールアドレスに飼主様情報変更完了のメールをお送りいたしました。'
  }
}
