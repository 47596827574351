export default {
  en: {
    buttonBack: 'Modify',
    buttonNext: 'Make a reservation',
    buttonProceed: 'Proceed to Email Authentication',
    errorChangesUnavailable: 'Online reservations are unavailable.',
    errorCommunication: 'A communication error has occurred.',
    errorContactClinic:
      'Please contact the clinic directly in order to modify your reservation',
    errorInvalidDate:
      'The date you selected is unavailable.\nPlease select another date.',
    errorInvalidReservationPurpose:
      'The purpose of visit you have selected is not available.\nPlease select another one.',
    errorInvalidTime:
      'The time you selected is unavailable.\nPlease select another time.',
    errorMemoRequired: 'Please enter any additional information.',
    errorOwnerDeleted: `The owner's data has been deleted.\nPlease contact the clinic directly.`,
    errorPatientLimitExceeded:
      'The number of patients has exceeded the limit.\nPlease modify the number of patients.',
    errorRegistrationFailed: 'Could not make reservation',
    errorRegistrationUpdateFailed:
      'Your reservation request could not proceed because it has already been modified or cancelled.',
    errorReservationLimitExceeded:
      'Further reservations are not allowed because the maximum number of reservations has been reached.',
    errorReservationUnavailable: 'Online reservations are unavailable.',
    errorUnavailableTime:
      'You cannot make a reservation for the selected appointment time.\nPlease select another time.',
    pageTitle: 'Review reservation information'
  },
  ja: {
    buttonBack: '修正する',
    buttonNext: '予約登録する',
    buttonProceed: 'メール認証に進む',
    errorChangesUnavailable:
      '予約変更受付が無効になっているため予約登録ができません。',
    errorCommunication: '通信エラーが発生しました。',
    errorContactClinic:
      'こちらの予約を変更する場合、医院の方へ直接お問い合わせ下さい。',
    errorInvalidDate:
      '選択した予約日は受付期間外です。他の日付を選択してください。',
    errorInvalidReservationPurpose:
      '予約登録できない来院目的が選択されています。他の来院目的を選択してください。',
    errorInvalidTime:
      '選択した予約時間は受付時間外です。他の時間を選択してください。',
    errorMemoRequired: '備考を入力して下さい。',
    errorOwnerDeleted:
      '飼い主様のデータが削除されています。医院へ直接お問い合わせください。',
    errorPatientLimitExceeded:
      '患者数が受け入れ上限を超えています。患者数を変更してください。',
    errorRegistrationFailed: '予約登録できませんでした。',
    errorRegistrationUpdateFailed:
      '予約がすでに変更またはキャンセルされているため予約登録ができません。',
    errorReservationLimitExceeded: '予約上限に達したため予約登録ができません。',
    errorReservationUnavailable:
      '予約受付が無効になっているため予約登録ができません。',
    errorUnavailableTime:
      '選択した予約時間は予約登録ができません。他の時間を選択してください。',
    pageTitle: '登録内容の確認'
  }
}
