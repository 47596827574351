export default {
  en: {
    netUnavailableMessage: 'Online reservations are currently not accepted.',
    onlyCheckedMessage:
      'Only reservations and owner/patient information can be checked.',
    pageTitle: 'Menu',
    popupMessage: 'Modification failed. Please wait a moment and try again.',
    reachedToMaxMessage:
      'The maximum number of online reservations has been reached.'
  },
  ja: {
    netUnavailableMessage: 'ネットからの予約は現在受け付けておりません。',
    onlyCheckedMessage: '予約・飼主・患者情報の確認のみ可能です。',
    pageTitle: 'メニュー',
    popupMessage: '更新に失敗しました。暫くしてから再度お試し下さい。',
    reachedToMaxMessage: 'ネットからの予約の上限数に達しました。'
  }
}
