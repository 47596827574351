<template>
  <div class="announce-popup">
    <base-back-ground-cover :layerNumber="layerNumber">
      <div class="popup" :style="{ zIndex }">
        <div
          class="header"
          :class="{ alert: isAlert, success: isSuccess, failure: isFailure }"
        >
          <div class="title">{{ title }}</div>
        </div>
        <div class="mark">
          <img
            src="@/assets/alert.png"
            v-if="isAlert"
            width="80px"
            height="83px"
            alt="alert"
          />
          <img
            src="@/assets/success.png"
            v-if="isSuccess"
            width="90px"
            height="90px"
            alt="success"
          />
          <img
            src="@/assets/failure.png"
            v-if="isFailure"
            width="72px"
            height="72px"
            alt="failure"
          />
        </div>
        <div class="content" :style="contentStyle">
          <span v-if="sessionTimeoutByServer">{{
            $t('parts.timeoutMessage')
          }}</span>
          <span v-else><slot></slot></span>
        </div>
        <div class="footer">
          <slot name="footer">
            <popup-footer-one-button
              v-if="buttonNum === 1"
              :oneButtonColor="sessionTimeout ? 'orange' : 'white'"
              :text="buttons[0]"
              @close="close"
            />
            <popup-footer-two-buttons
              v-if="buttonNum === 2"
              :cancelText="buttons[0]"
              :decisionText="buttons[1]"
              @cancel="cancel"
              @decision="decision"
            />
          </slot>
        </div>
      </div>
    </base-back-ground-cover>
  </div>
</template>

<script>
import BaseBackGroundCover from '@/components/parts/atoms/BaseBackGroundCover'
import PopupFooterTwoButtons from '@/components/parts/molecules/PopupFooterTwoButtons'
import PopupFooterOneButton from '@/components/parts/molecules/PopupFooterOneButton'

export default {
  name: 'AnnouncePopup',

  components: {
    PopupFooterOneButton,
    PopupFooterTwoButtons,
    BaseBackGroundCover
  },

  props: {
    isGlobalPopup: { type: Boolean, default: false },
    layerNumber: { type: Number, default: 1 },
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    contentStyle: { type: Object, default: () => ({}) },
    buttons: {
      type: Array,
      require: false,
      default: () => ['閉じる'],
      validator: function(arr) {
        if (arr.length !== 1 && arr.length !== 2) {
          return false
        }
        arr.forEach(v => {
          if (typeof v !== 'string') return false
        })
        return true
      }
    }
  },

  computed: {
    buttonNum() {
      return this.buttons.length
    },
    sessionTimeout() {
      return (
        this.buttonNum === 1 &&
        (this.buttons[0] === 'ログイン画面へ' ||
          this.buttons[0] === 'Go to login screen')
      )
    },
    sessionTimeoutByServer() {
      // globalPopup でのセッションタイムアウトはフロントエンド由来のため区別
      return this.sessionTimeout && !this.isGlobalPopup
    },
    isAlert() {
      return this.type === 'alert'
    },
    isSuccess() {
      return this.type === 'success'
    },
    isFailure() {
      return this.type === 'failure'
    },
    zIndex() {
      return this.layerNumber * 200 + 100
    }
  },

  methods: {
    close() {
      if (this.sessionTimeoutByServer) this.pushToLoginTop()
      this.$emit('close')
    },
    cancel() {
      this.$emit('cancel')
    },
    decision() {
      this.$emit('decision')
    },
    pushToLoginTop() {
      const encryptedClinicId = !process.env.CI
        ? sessionStorage.getItem('encryptedClinicId')
        : 'clinicIdToken'
      this.$router.push({
        name: 'LoginTop',
        query: { clinic: encryptedClinicId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.announce-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .popup {
    display: flex;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .header {
      border-bottom: solid 1px #{$plus_ce_gray};
      display: flex;
      height: 46.5px;
      width: 100%;
      justify-content: center;
      align-items: center;
      &.alert,
      &.failure {
        background-color: #{$plus_de_red};
      }
      &.success {
        background-color: #{$plus_orange};
      }
      > .title {
        text-align: center;
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #{$plus_white};
      }
    }
    > .mark {
      margin-top: 20px;
      text-align: center;
    }
    > .content {
      display: flex;
      margin: 20px;
      justify-content: center;
      white-space: pre-line;
    }
    > .footer {
      display: flex;
      margin-top: auto;
      padding-top: 20px;
      padding-bottom: 33px;
      justify-content: center;
      min-width: 244px;
    }
  }
}
@media (max-width: 500px) {
  .popup {
    width: 90vw;
  }
}
</style>
