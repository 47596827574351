//バリデーション 正規表現
// email RFCに準拠
// ※参考にしたEメールアドレスの正規表現
//  https://qiita.com/sakuro/items/1eaa307609ceaaf51123
export const VALID_EMAIL_EMPTY_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^$/

// tel
export const VALID_TEL_EMPTY_REGEX = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$|^$/

// kana カタカナのみ
export const VALID_KATAKANA_EMPTY_REGEX = /^[ァ-ンヴー]+$|^$/

//password 半角英数記号のみ
//https://www-creators.com/archives/5187
//https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
export const VALID_PASSWORD_REGEX = /^[!-~]+$/

// postalCode
export const VALID_POSTALCODE_EMPTY_REGEX = /^\d{7}$|^$/
export const POSTAL_CODE_API_URL =
  'https://madefor.github.io/postal-code-api/api/v1/'

export const SESSION_STORAGE_KEY = 'Gh7iFcy36;m5![GQ9B'
