<template>
  <div class="the-header">
    <header class="the-header-whole">
      <img
        src="@/assets/the_header.png"
        class="the-header-img"
        width="199px"
        height="34px"
        alt="the-header"
      />
      <div v-if="reservationSetting.englishOptionFlg" class="language">
        <img src="@/assets/language.png" class="lang-icon" />
        <base-select-box
          :value="languageValue"
          :select-data="selectData"
          @input="changeLanguage"
          class="select-lang"
        />
      </div>
      <div class="menu">
        <img
          src="@/assets/menu.png"
          class="menu-icon"
          v-if="loginFlg"
          @click="menuFlg = !menuFlg"
          data-test="menu-icon"
          width="28px"
          height="19px"
          alt="menu-icon"
        />
      </div>
    </header>
    <menu-box v-if="menuFlg" class="menu-box" @click="menuFlg = false" />
    <div
      class="cover"
      v-if="menuFlg"
      @click="menuFlg = false"
      data-test="cover"
    ></div>
    <announce-popup
      v-if="alertFlg"
      @close="alertFlg = false"
      :title="$t('common.error')"
      :buttons="[$t('common.close')]"
      :type="'failure'"
      >{{ $t(`${popupMessage}`) }}</announce-popup
    >
  </div>
</template>

<script>
import MenuBox from '@/components/parts/atoms/MenuBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { getAxiosObject } from '@/utils/library'

export default {
  name: 'TheHeader',
  components: {
    MenuBox,
    BaseSelectBox,
    AnnouncePopup
  },
  data() {
    return {
      menuFlg: false,
      languageValue: 1,
      selectData: [
        { id: 1, name: '日本語' },
        { id: 2, name: 'English' }
      ],
      alertFlg: false,
      popupMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      loginFlg: 'auth/logined',
      reservationSetting: 'reservationSetting/getReservationSetting',
      petorelu: 'petorelu/get',
      owner: 'owner/getOwner'
    })
  },
  watch: {
    '$i18n.locale': function(newVal) {
      this.languageValue = newVal === 'en' ? 2 : 1
    }
  },
  methods: {
    async changeOwnerEnglishFlg(englishFlg) {
      try {
        const axiosObject = getAxiosObject()
        await axiosObject.put('/owners', { englishFlg })
        this.$store.dispatch('owner/addOwner', { ...this.owner, englishFlg })
      } catch (ex) {
        this.popupMessage =
          ex.response?.data?.message === 'no data' &&
          ex.response.data.extra === 'owner'
            ? 'parts.errorOwnerDeleted'
            : 'parts.errorCommunication'
        this.alertFlg = true
      }
    },
    changeLanguage(id) {
      this.$store.dispatch('petorelu/recordLanguageChangeAction')
      if (id === 1) {
        this.$i18n.locale = 'ja'
        this.languageValue = 1
        if (this.loginFlg) this.changeOwnerEnglishFlg(0)
      } else {
        this.$i18n.locale = 'en'
        this.languageValue = 2
        if (this.loginFlg) this.changeOwnerEnglishFlg(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.the-header {
  > .the-header-whole {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: solid 1px #{$plus_orange};
    background-color: #{$plus_white};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > .the-header-img {
      margin-left: 33px;
      margin-right: auto;
    }
    > .language {
      margin-right: 20px;
      display: flex;
      > .lang-icon {
        margin-top: 3px;
        height: 40px;
      }
    }
    > .menu {
      margin-right: 20px;
      display: flex;
      > .menu-icon {
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
  > .menu-box {
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 110;
  }
  > .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    z-index: 90;
  }
}
@media (max-width: $global-container) {
  .the-header {
    > .the-header-whole {
      width: 100vw;
      > .the-header-img {
        margin-left: 5px;
        margin-top: 4px;
        width: 150px;
        height: 25px;
      }
      > .language {
        > .lang-icon {
          height: 30px;
          margin-top: 7px;
        }
      }
      > .menu {
        width: 28px;
      }
    }
  }
}
</style>
