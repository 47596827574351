export default {
  en: {
    pageTitle: 'Content check',
    popupMessageEmail: 'The email address entered is already in use.',
    popupMessageKarte: 'Patients with medical records cannot be deleted.',
    popupMessageReservationRequest:
      'Patients with a registered reservation cannot be deleted.',
    popupMessageReservation:
      'Patients with a registered reservation cannot be deleted.',
    popupMessagePatient: `Some of this patient's data has already been deleted.`,
    popupMessageError: 'The modification failed.'
  },
  ja: {
    pageTitle: '内容の確認',
    popupMessageEmail: '登録されたメールアドレスは既に使用されています。',
    popupMessageKarte: 'カルテのある患者は削除できません。',
    popupMessageReservationRequest:
      '今日以降の申請中の予約で使用されている患者は削除できません。',
    popupMessageReservation:
      '今日以降の予約で使用されている患者は削除できません。',
    popupMessagePatient: '既に削除された患者データがあります。',
    popupMessageError: '変更に失敗しました。'
  }
}
