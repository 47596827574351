<template>
  <div class="base-button-medium-white">
    <button type="button" :disabled="disabled" @click="handleClick">
      <span><slot /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonMediumWhite',

  props: {
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-medium-white {
  > button {
    height: 44px;
    width: 200px;
    border-radius: 6px;
    border: solid 2px #{$plus_ce_gray};
    background-color: #{$plus_white};
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$plus_f7_gray};
      color: #{$plus_ce_gray};
      border: none;
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 42px;
    }
    > span {
      height: 29px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.45;
    }
  }
}
</style>
