<template>
  <span class="base-required-mark">{{ $t('parts.required') }}</span>
</template>

<script>
export default {
  name: 'BaseRequiredMark'
}
</script>

<style lang="scss" scoped>
.base-required-mark {
  height: 30px;
  @include font-size(18);
  font-weight: bold;
  text-align: left;
  color: #{$plus_red};
}
</style>
