export default {
  en: {
    message: 'The following reservation has been cancelled:',
    pageTitle: 'Cancellation Complete'
  },
  ja: {
    message: '下記の内容をキャンセルいたしました。',
    pageTitle: 'キャンセル完了'
  }
}
