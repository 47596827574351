<template>
  <div class="password-reset">
    <base-loading :waitFlg="apiWaitFlg" />
    <div
      class="password-reset-field"
      v-if="!apiWaitFlg && !versionUploadingFlg"
    >
      <validation-observer v-slot="{ invalid }" class="observer">
        <div class="page-title">
          <base-page-title>{{
            $t('passwordReset.resetPasswordTopic')
          }}</base-page-title>
        </div>
        <div class="password-reset-progress-bar">
          <progress-bar v-bind="progressBarData" />
        </div>
        <div class="password-reset-text">
          {{ $t('passwordReset.setNewPasswordNotice') }}
        </div>
        <div class="password-reset-container">
          <div class="password-reset-input">
            <div class="password-reset-input-label re">
              {{ $t('common.labelPassword') }} <base-required-mark />
            </div>
            <validation-provider
              rules="requiredRule|regexHalfWidth|minPassRule:8"
              v-slot="{ errors }"
              class="provider"
            >
              <base-text-input-password
                data-test="password"
                :newFlg="true"
                v-model="ownerPassword"
              />
              <div class="password-reset-err-area">
                <div class="error" data-test="error-password">
                  {{ $t(errors[0]) }}
                </div>
              </div>
            </validation-provider>
          </div>
          <div class="password-reset-input">
            <div class="password-reset-input-label re">
              {{ $t('common.labelPasswordReEnter') }} <base-required-mark />
            </div>
            <validation-provider
              :rules="{
                requiredRule: true,
                regexHalfWidth: true,
                minPassRule: 8,
                isRePassRule: ownerPassword
              }"
              v-slot="{ errors }"
              class="provider"
            >
              <base-text-input-password
                data-test="password-re"
                :newFlg="true"
                v-model="reEnterPassword"
              />
              <div class="password-reset-err-area">
                <div class="error" data-test="error-password-re">
                  {{ $t(errors[0]) }}
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="submit-button">
          <base-decision-button
            @click="handleSave"
            :disabled="invalid || waitFlg"
            >{{ $t('common.buttonNext') }}</base-decision-button
          >
        </div>
        <announce-popup
          v-if="alertFlg"
          @close="alertFlg = false"
          :title="$t('common.error')"
          :buttons="[$t('common.close')]"
          :type="'failure'"
          >{{ popupMessage }}</announce-popup
        >
      </validation-observer>
    </div>
    <div v-else-if="!apiWaitFlg && versionUploadingFlg" class="error">
      <base-page-title class="title" data-test="title ja">{{
        '現在この予約システムをアップデートしております。暫くお待ちになってから再度お試し下さい。'
      }}</base-page-title>
      <base-page-title class="title" data-test="title en">{{
        'Currently under maintenance. Please wait a moment and try again.'
      }}</base-page-title>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import BaseTextInputPassword from '@/components/parts/atoms/BaseTextInputPassword.vue'
import BaseRequiredMark from '@/components/parts/atoms/BaseRequiredMark.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseLoading from '@/components/parts/atoms/BaseLoading.vue'
import axios from 'axios'
import { getApiUrl, getAxiosObject } from '@/utils/library'
import '@/utils/validation_rules'

export default {
  name: 'PasswordReset',

  components: {
    ValidationObserver,
    ValidationProvider,
    BasePageTitle,
    ProgressBar,
    BaseTextInputPassword,
    BaseRequiredMark,
    BaseDecisionButton,
    AnnouncePopup,
    BaseLoading
  },

  data() {
    return {
      apiWaitFlg: true,
      versionUploadingFlg: false,
      progressBarData: {
        textList: [
          'passwordReset.enterEmailAddress',
          'passwordReset.userAuthentication',
          'passwordReset.setNewPassword',
          'passwordReset.finish'
        ],
        barWidthPc: 'password-resetting-screen',
        textOrangeNumber: 3
      },
      ownerPassword: '',
      reEnterPassword: '',
      alertFlg: false,
      popupMessage: '',
      waitFlg: false
    }
  },

  mounted() {
    this.authenticateToken()
  },

  methods: {
    async authenticateToken() {
      try {
        const axiosObject = axios.create({
          baseURL: getApiUrl(),
          headers: {
            Authorization: 'Bearer ' + this.$route.query.token,
            'Content-Type': 'application/json',
            'Net-Version': process.env.VERSION
          }
        })
        const response = await axiosObject.get('/entrance')
        this.setLocale()
        this.$store.dispatch('auth/authLogin', this.$route.query.token)
        this.$store.dispatch('clinic/addClinic', response.data.clinic)
        this.$store.dispatch(
          'reservationSetting/addReservationSetting',
          response.data.reservationSetting
        )
        this.$store.dispatch(
          'reservationPurpose/addReservationPurposes',
          response.data.reservationPurposes
        )
        this.$store.dispatch('species/addSpecies', response.data.species)
        this.$store.dispatch('master/setData', response.data.master)
        sessionStorage.setItem('encryptedClinicId', this.$route.query.clinic)
        this.apiWaitFlg = false
      } catch (error) {
        if (
          error.response?.status === 400 &&
          error.response?.data?.extra === 'not the same as required version'
        ) {
          this.versionUploadingFlg = true
          this.apiWaitFlg = false
        } else {
          this.$router.push({
            path: '/login-top',
            query: { clinic: this.$route.query.clinic }
          })
        }
      }
    },
    async handleSave() {
      this.waitFlg = true
      try {
        const axiosObject = getAxiosObject()
        const response = await axiosObject.put('/password-reset', {
          password: this.reEnterPassword
        })
        this.$store.dispatch('auth/authLogin', response.data.token)
        this.$store.dispatch('owner/addOwner', response.data.owner)
        this.$store.dispatch('patient/addPatients', response.data.patients)
        this.$store.dispatch(
          'reservation/setReservations',
          response.data.reservations
        )
        this.$store.dispatch(
          'reservationRequests/setData',
          response.data.reservationRequests
        )
        this.$router.push({ name: 'PasswordResetRegistered' })
      } catch (error) {
        this.popupMessage = this.$t('passwordReset.passwordRegistrationFailure')
        this.alertFlg = true
        this.waitFlg = false
      }
    },
    setLocale() {
      const locales = ['en', 'ja']
      const locale = locales.includes(this.$route.query.lang)
        ? this.$route.query.lang
        : 'ja'
      this.$i18n.locale = locale
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset {
  margin-bottom: 100px;
  > .password-reset-field {
    > .observer {
      > .page-title {
        margin-top: 70px;
      }
      > .password-reset-progress-bar {
        margin-top: 27px;
      }
      > .password-reset-text {
        margin: 37px auto 0;
        padding-bottom: 18px;
        font-weight: bold;
        @include font-size(18);
      }
      > .password-reset-container {
        margin: 0 auto;
        min-width: 691px;
        width: fit-content;
        > .password-reset-input {
          width: 100%;
          margin-top: 24px;
          @include clearfix;
          font-weight: bold;
          > .password-reset-input-label {
            float: left;
            line-height: 45px;
            @include font-size(18);
            &.re {
              margin-right: 43px;
            }
          }
          > .provider {
            float: right;
            > .password-reset-err-area {
              position: relative;
              > .error {
                position: absolute;
                z-index: 100;
                color: #{$plus_red};
                font-size: 12px;
              }
            }
          }
        }
      }
      > .submit-button {
        margin-top: 78px;
      }
    }
  }
  > .error {
    padding: 0 20px;
    > .title {
      margin-top: 70px;
    }
  }
}
@media (max-width: $global-media-width) {
  .password-reset {
    > .password-reset-field {
      > .observer {
        > .password-reset-text {
          margin: 54px auto 0px;
          padding-bottom: 24px;
          max-width: 410px;
          width: calc-percent(410px, 640px);
        }
        > .password-reset-container {
          min-width: calc-percent(410px, 640px);
          > .password-reset-input {
            margin: 20px auto 0;
            width: 100%;
            > .password-reset-input-label {
              float: none;
              margin-right: 0px;
              line-height: 27px;
              text-align: left;
              max-width: 410px;
              width: calc-percent(410px, 640px);
              margin: 0 auto 10px;
              &.re {
                margin-right: auto;
              }
            }
            > .provider {
              float: none;
              > .password-reset-err-area {
                max-width: 410px;
                width: calc-percent(410px, 640px);
                margin: 0 auto;
              }
            }
          }
        }
        > .submit-button {
          margin-top: 84px;
        }
      }
    }
  }
}
</style>
