export default {
  en: {
    apiResultPostalCode: 'No address was found for the entered postal code',
    emailRuleMessage: 'Enter a valid email address',
    invalidWordMessage: 'The name contains invalid characters',
    isRePassRuleMessage: 'The passwords do not match',
    minPassRuleMessage: 'The password should be more than 8 characters',
    nameRuleMessage: 'The name contains invalid characters',
    regexHalfWidthMessage:
      'Please use half-width alphanumeric characters and symbols',
    regexKanaMessage: 'Enter full-width kana',
    regexTelMessage: 'Enter a valid phone number',
    requiredRuleMessage: 'Required',
    usedMailMessage: 'This email address is already used',
    regexPostalCodeMessage: 'Enter a valid postal code'
  },
  ja: {
    apiResultPostalCode: '入力された郵便番号の住所が見つかりませんでした。',
    emailRuleMessage: '有効なメールアドレスを入力して下さい',
    invalidWordMessage: '使用できない文字が含まれています',
    isRePassRuleMessage: 'パスワードが一致していません',
    nameRuleMessage: '使用できない文字が含まれています',
    regexHalfWidthMessage: '半角英数字記号で入力して下さい',
    regexKanaMessage: '全角カナで入力して下さい',
    regexTelMessage: '有効な電話番号を入力して下さい',
    minPassRuleMessage: '8文字以上で入力して下さい',
    requiredRuleMessage: '入力必須項目です',
    usedMailMessage: '既に使用されているメールアドレスです',
    regexPostalCodeMessage: '7桁の半角数字で入力して下さい'
  }
}
