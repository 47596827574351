<template>
  <div id="app">
    <div id="all-screen-wrapper">
      <div ref="netVersion" hidden>netVersion:{{ version }}</div>
      <div ref="backVersion" hidden>backVersion:{{ backVersion }}</div>
      <div id="main-view">
        <the-header />
        <router-view />
      </div>
      <announce-popup
        v-if="netVersionUpFlg"
        :type="'alert'"
        :title="$t('app.popupTitleVersionError')"
        :buttons="[$t('common.buttonClose'), $t('common.buttonRefresh')]"
        :contentStyle="{
          fontSize: '15px',
          textAlign: 'left',
          margin: '10px'
        }"
        @cancel="setNetVersionUpFlgFalse"
        @decision="reload"
        >{{ $t(popupMessage, { serviceName }) }}</announce-popup
      >
      <announce-popup
        v-if="globalPopup.show"
        :isGlobalPopup="true"
        :layerNumber="globalPopup.layerNumber"
        :type="globalPopup.type"
        :title="globalPopup.title"
        :buttons="globalPopup.buttons"
        @close="globalPopup.close"
        @cancel="globalPopup.cancel"
        @decision="globalPopup.decision"
        >{{ globalPopup.message }}</announce-popup
      >
    </div>
  </div>
</template>

<script>
import 'normalize.css'
import TheHeader from '@/components/globals/TheHeader.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { SESSION_STORAGE_KEY } from '@/utils/define'
import { logout, getClinicData } from '@/components/mixins/ApiReset'

export default {
  head: {
    meta: function() {
      return [{ name: 'robots', content: 'noindex' }]
    }
  },

  name: 'App',

  components: {
    TheHeader,
    AnnouncePopup
  },

  data() {
    return {
      version: process.env.VERSION,
      timeoutId: null
    }
  },

  watch: {
    token(token) {
      if (token !== '') {
        clearTimeout(this.timeoutId)
        this.timeoutId = window.setTimeout(() => {
          this.expiredAnnounce()
        }, process.env.VUE_APP_EXPIRE_IN * 60 * 60 * 1000)
      }
    }
  },

  computed: {
    backVersion() {
      return this.$store.getters['auth/backVersion']
    },
    netVersionUpFlg() {
      return this.$store.getters['petorelu/get'].netVersionUpFlg
    },
    popupMessage() {
      return 'app.popupMessageVersionError'
    },
    clinic() {
      return this.$store.getters['clinic/getClinic']
    },
    serviceName() {
      const clinic = this.clinic
      const serviceName = clinic.name
        ? this.$i18n.locale === 'en'
          ? clinic.nameEnglish || clinic.name
          : clinic.name
        : 'PETORELU+'
      return serviceName
    },
    globalPopup() {
      return this.$store.getters['petorelu/get'].globalPopup
    },
    token() {
      return this.$store.getters['auth/token']
    }
  },

  created() {
    logout()
    if (!process.env.CI) {
      if (!JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY))) {
        getClinicData(this.$route.query.clinic)
      }
    }
  },

  methods: {
    setNetVersionUpFlgFalse() {
      this.$store.dispatch('petorelu/setNetVersionUpFlgToFalse')
    },
    reload() {
      location.reload()
    },
    expiredAnnounce() {
      this.$store.dispatch('petorelu/showGlobalPopup', {
        type: 'alert',
        title: this.$t('app.popupTitleSessionError'),
        buttons: [this.$t('common.buttonGoToLogin')],
        message: this.$t('app.popupMessageSessionError'),
        close: () => {
          this.$store.dispatch('petorelu/hideGlobalPopup')
          this.$router.push({
            path: '/login-top',
            query: { clinic: sessionStorage.getItem('encryptedClinicId') }
          })
          clearTimeout(this.timeoutId)
        }
      })
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #{$plus_black};
  margin: 0 auto;
}
#all-screen-wrapper {
  width: 100%;
}
#main-view {
  margin: auto;
  padding-top: 60px;
}
@media (min-width: 960px) {
  #main-view {
    width: 100vw;
  }
}
@media (max-width: 960px) {
  #main-view {
    max-width: 640px;
    width: 100%;
  }
}
</style>
