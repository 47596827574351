export default {
  en: {
    pageTitle: 'Reset password',
    textGoToSettingPage:
      'Please use the link in the email to go to the password setting page.',
    textIfNotReceiveEmail:
      'If you do not receive the e-mail, please check your spam folder.',
    textSentEmail:
      'Identity verification email has been sent to the registered email address.'
  },
  ja: {
    pageTitle: 'パスワードの発行/再設定',
    textGoToSettingPage:
      'メールに記載されたリンクからパスワード設定ページに移動してください。',
    textIfNotReceiveEmail:
      'メールが届かない場合は、迷惑メールフォルダー等もご確認ください。',
    textSentEmail:
      '登録されたメールアドレスへ本人確認用のメールを送信しました。'
  }
}
