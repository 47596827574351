<template>
  <div class="base-select-box">
    <div class="select-wrapper">
      <select
        class="select-box"
        :value="value"
        @change="selectValue"
        @blur="$emit('blur')"
        ref="selectBox"
      >
        <option value="" class="placeholder" disabled></option>
        <option v-for="datum in selectData" :value="datum.id" :key="datum.id">
          {{ datum.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSelectBox',

  props: {
    // valueのtype 親コンポで渡すときは''(String)、選択したあとは数値（Number）が入る
    value: { type: [String, Number] },
    selectData: { type: Array },
    initialValidate: { type: Boolean, default: false }
  },

  mounted() {
    if (this.initialValidate) {
      this.$refs.selectBox.focus()
      this.$refs.selectBox.blur()
    }
  },

  methods: {
    selectValue(e) {
      const selectNumber = parseInt(e.target.value)
      this.$emit('input', selectNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-select-box {
  width: 110px;
  > .select-wrapper {
    display: inline-block;
    position: relative;
    background-color: #{$plus_white};
    border-radius: 5px;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 11px;
      width: 9px;
      height: 9px;
      border: 0px;
      border-bottom: solid 2px #{$plus_orange};
      border-right: solid 2px #{$plus_orange};
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -6px;
    }
    > .select-box {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      position: relative;
      z-index: 1;
      height: 45px;
      width: 100%;
      padding: 0 26px 0 8px;
      background: transparent;
      border: solid 2px #{$plus_ce_gray};
      border-radius: 5px;
      > .option-default-none {
        display: none;
      }
      > .placeholder {
        display: none;
      }
    }
    > .select-box::-ms-expand {
      display: none;
    }
  }
}
@media (max-width: 450px) {
  .base-select-box {
    width: 80px;
  }
}
</style>
