export default {
  en: {
    pageTitle: 'Purpose of visit',
    popupButtonClose: 'Close',
    popupButtonToLogin: 'Go to login screen',
    popupMessageError: 'Failed to update data. Please try again in a while.',
    popupTitle: 'Error'
  },
  ja: {
    pageTitle: '来院目的',
    popupButtonClose: '閉じる',
    popupButtonToLogin: 'ログイン画面へ',
    popupMessageError:
      'データの更新に失敗しました。暫くしてから再度お試し下さい。',
    popupTitle: '失敗'
  }
}
