<template>
  <div class="base-decision-button">
    <button
      class="base-decision-button-class"
      type="button"
      :disabled="disabled || preventContinuousClickFlg"
      @click="handleClick"
      data-e2e="base-decision-button"
    >
      <span class="base-decision-button-text">
        <slot />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseDecisionButton',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      preventContinuousClickFlg: false,
      id: null
    }
  },

  watch: {
    disabled: function() {
      this.$emit('validate', this.disabled)
    }
  },

  beforeDestroy() {
    clearTimeout(this.id)
  },

  methods: {
    handleClick() {
      this.preventContinuousClickFlg = true
      this.$emit('click')
      this.id = setTimeout(() => {
        this.preventContinuousClickFlg = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-decision-button {
  > .base-decision-button-class {
    height: 60px;
    border-radius: 6px;
    background-color: #{$plus_orange};
    cursor: pointer;
    border: none;
    color: #{$plus_white};
    &:disabled {
      pointer-events: none;
      background-color: #{$plus_f7_gray};
      color: #{$plus_be_gray};
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 58px;
    }
    > .base-decision-button-text {
      height: 29px;
      @include font-size(20);
      font-weight: bold;
      line-height: 1.45;
    }
  }
  @include selector-width('.base-decision-button-class', 335px);
}
</style>
