export default {
  en: {
    message: 'Password has been set.',
    pageTitle: 'Password Setting Completed'
  },
  ja: {
    message: 'パスワードの設定が完了しました。',
    pageTitle: 'パスワードの設定完了'
  }
}
