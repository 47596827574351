<template>
  <div class="base-for-guest-button">
    <button class="for-guest-button" type="button" @click="handleClick">
      <span class="for-guest-button-text"><slot /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseForGuestButton',

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-for-guest-button {
  @include selector-width('.for-guest-button', 410px);
  > .for-guest-button {
    height: 70px;
    border-radius: 6px;
    background-color: #{$plus_light_orange};
    border: none;
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 68px;
    }
    > .for-guest-button-text {
      height: 29px;
      @include font-size(18);
      font-weight: bold;
      line-height: 1.45;
      color: #{$plus_white};
    }
  }
}
</style>
