import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueHead from 'vue-head'
import { i18n } from './i18n'

Vue.config.productionTip = false
Vue.use(VueHead)

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app')
