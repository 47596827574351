const state = {
  reservationSetting: {}
}

const mutations = {
  setData(state, reservationSetting) {
    state.reservationSetting = reservationSetting
  }
}

const getters = {
  getReservationSetting(state) {
    return state.reservationSetting
  },
  getReservationTimeUnit(state) {
    return state.reservationSetting.reservationTimeUnit
  }
}

const actions = {
  addReservationSetting({ commit }, reservationSetting) {
    return commit('setData', reservationSetting)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
