export default {
  en: {
    pageTitle: 'Modify Information',
    popupMessageSeverError: 'A server error has occurred.',
    popupMessageTryAgain:
      'A server error has occurred.\nPlease try again after a while.'
  },
  ja: {
    pageTitle: '飼主様情報の変更',
    popupMessageSeverError: 'サーバーエラーが発生しました。',
    popupMessageTryAgain:
      'サーバーエラーが発生しました。\n暫くしてから再度お試し下さい。'
  }
}
