import { extend } from 'vee-validate'
import * as Rules from 'vee-validate/dist/rules'
import * as Define from '@/utils/define'

export const requiredRule = extend('requiredRule', {
  ...Rules.required,
  message: 'utils.requiredRuleMessage'
})

export const regexKana = extend('regexKana', {
  message: 'utils.regexKanaMessage',
  validate(value) {
    return Define.VALID_KATAKANA_EMPTY_REGEX.test(value) ? true : false
  }
})

export const emailRule = extend('emailRule', {
  message: 'utils.emailRuleMessage',
  validate(value) {
    const localPart = value.slice(0, value.lastIndexOf('@'))
    return Define.VALID_EMAIL_EMPTY_REGEX.test(value) &&
      localPart.length <= 64 &&
      value.length <= 254
      ? true
      : false
  }
})

export const regexTel = extend('regexTel', {
  message: 'utils.regexTelMessage',
  validate(value) {
    return Define.VALID_TEL_EMPTY_REGEX.test(value) ? true : false
  }
})

export const minPassRule = extend('minPassRule', {
  ...Rules.min,
  message: 'utils.minPassRuleMessage'
})

export const isRePassRule = extend('isRePassRule', {
  ...Rules.is,
  message: 'utils.isRePassRuleMessage'
})

export const regexHalfWidth = extend('regexHalfWidth', {
  message: 'utils.regexHalfWidthMessage',
  validate(value) {
    return Define.VALID_PASSWORD_REGEX.test(value) ? true : false
  }
})

// ひらがな・カタカナ・漢字・アルファベット・数字のみ許可
export const nameRule = extend('nameRule', {
  message: 'utils.nameRuleMessage',
  validate(value) {
    return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcfa-zA-Zａ-ｚＡ-Ｚ0-9０-９]+$/.test(
      value
    )
  }
})

// 非サロゲートペア
export const notSurrogatePair = extend('notSurrogatePair', {
  message: 'utils.invalidWordMessage',
  validate(value) {
    return !/[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value) ? true : false
  }
})

export const regexPostalCode = extend('regexPostalCode', {
  message: 'utils.regexPostalCodeMessage',
  validate(value) {
    return Define.VALID_POSTALCODE_EMPTY_REGEX.test(value)
  }
})

export const apiResultPostalCode = extend('apiResultPostalCode', {
  message: 'utils.apiResultPostalCode',
  params: ['apiResultPostalCode'],
  validate(value, { apiResultPostalCode }) {
    return (
      apiResultPostalCode === null || apiResultPostalCode.existingPostalCodeFlg
    )
  }
})

// エラーメッセージ
export const usedMailMessage = 'utils.usedMailMessage'
