<template>
  <div class="popup-footer-two-buttons">
    <div class="two-buttons">
      <base-button-medium-white @click="cancelClick">
        {{ cancelText }}</base-button-medium-white
      >
    </div>
    <div class="two-buttons">
      <base-button-medium-orange @click="decisionClick" :disabled="disabledFlg">
        {{ decisionText }}
      </base-button-medium-orange>
    </div>
  </div>
</template>

<script>
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
export default {
  name: 'PopupFooterTwoButtons',
  components: {
    BaseButtonMediumOrange,
    BaseButtonMediumWhite
  },
  props: {
    cancelText: {
      type: String,
      default: '閉じる'
    },
    decisionText: {
      type: String,
      default: '登録'
    },
    disabledFlg: { type: Boolean, default: false }
  },
  methods: {
    cancelClick() {
      this.$emit('cancel')
    },
    decisionClick() {
      this.$emit('decision')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-footer-two-buttons {
  display: flex;
  > .two-buttons {
    margin-left: 22px;
    margin-right: 22px;
  }
}
@media (max-width: 500px) {
  .popup-footer-two-buttons {
    display: block;
    > .two-buttons {
      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
}
</style>
