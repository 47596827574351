<template>
  <div class="base-loading">
    <div class="cover" v-if="waitFlg">
      <div class="loader">Loading...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoading',
  props: {
    waitFlg: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.base-loading {
  .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(45, 45, 45, 0.1);
    z-index: 300;
    > .loader {
      color: white;
      font-size: 50px;
      text-align: center;
      margin-top: 400px;
      text-shadow: 1px 0 5px black;
    }
  }
}
</style>
