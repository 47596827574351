const state = {
  prefectures: [],
  prefecturesIndexedById: {}
}

const getters = {
  selectPrefecturesZero(state) {
    return [{ id: 0, name: '', nameEnglish: '' }].concat(state.prefectures)
  },
  getDataById(state) {
    return (dataName, id) => state[`${dataName}IndexedById`][id]
  }
}

const actions = {
  setData({ commit }, obj) {
    commit('setData', obj)
  },
  deleteMasterData({ commit }) {
    commit('deleteData')
  }
}

const setDataIndexedById = (dataName, state) => {
  state[`${dataName}IndexedById`] = {}
  state[dataName].forEach(v => {
    state[`${dataName}IndexedById`][v.id] = v
  })
}

const mutations = {
  setData(state, obj) {
    state.prefectures = obj.prefectures
    setDataIndexedById('prefectures', state)
  },
  deleteData(state) {
    state.prefectures = []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
