import store from '@/store/store'

const okTransitionList = [
  {
    from: 'LoginTop',
    to: 'ReservationPurpose'
  },
  {
    from: 'LoginTop',
    to: 'ReservationMenu'
  },
  {
    from: 'LoginTop',
    to: 'PasswordResetSendMail'
  },
  {
    from: 'ReservationMenu',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationMenu',
    to: 'LoginTop'
  },
  {
    from: 'ReservationMenu',
    to: 'ReservationPurpose'
  },
  {
    from: 'ReservationMenu',
    to: 'ReservationConfirm'
  },
  {
    from: 'ReservationMenu',
    to: 'OwnerPatientsInformation'
  },
  {
    from: 'ReservationPurpose',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationPurpose',
    to: 'LoginTop'
  },
  {
    from: 'ReservationPurpose',
    to: 'ReservationDateSelect'
  },
  {
    from: 'ReservationPurpose',
    to: 'ReservationConfirm'
  },
  {
    from: 'ReservationDateSelect',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationDateSelect',
    to: 'LoginTop'
  },
  {
    from: 'ReservationDateSelect',
    to: 'ReservationTimeSelect'
  },
  {
    from: 'ReservationDateSelect',
    to: 'ReservationPurpose'
  },
  {
    from: 'ReservationTimeSelect',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationTimeSelect',
    to: 'LoginTop'
  },
  {
    from: 'ReservationTimeSelect',
    to: 'ReservationInputPage'
  },
  {
    from: 'ReservationTimeSelect',
    to: 'FirstReservationInputPage'
  },
  {
    from: 'ReservationTimeSelect',
    to: 'ReservationDateSelect'
  },
  {
    from: 'ReservationInputPage',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationInputPage',
    to: 'LoginTop'
  },
  {
    from: 'ReservationInputPage',
    to: 'RegistrationConfirmChangeReservation'
  },
  {
    from: 'ReservationInputPage',
    to: 'ReservationTimeSelect'
  },
  {
    from: 'RegistrationConfirmChangeReservation',
    to: 'ReservationMenu'
  },
  {
    from: 'RegistrationConfirmChangeReservation',
    to: 'LoginTop'
  },
  {
    from: 'RegistrationConfirmChangeReservation',
    to: 'ReservationRegistered'
  },
  {
    from: 'RegistrationConfirmChangeReservation',
    to: 'ReservationInputPage'
  },
  {
    from: 'ReservationRegistered',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationRegistered',
    to: 'LoginTop'
  },
  {
    from: 'FirstReservationInputPage',
    to: 'RegistrationConfirm'
  },
  {
    from: 'FirstReservationInputPage',
    to: 'ReservationTimeSelect'
  },
  {
    from: 'RegistrationConfirm',
    to: 'FirstReservationInputPage'
  },
  {
    from: 'RegistrationConfirm',
    to: 'SendMailFirstReservation'
  },
  {
    from: 'RegistrationConfirm',
    to: 'LoginTop'
  },
  {
    from: 'ReservationConfirm',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationConfirm',
    to: 'LoginTop'
  },
  {
    from: 'ReservationConfirm',
    to: 'ReservationPurpose'
  },
  {
    from: 'ReservationConfirm',
    to: 'ReservationCancelComplete'
  },
  {
    from: 'ReservationCancelComplete',
    to: 'ReservationMenu'
  },
  {
    from: 'ReservationCancelComplete',
    to: 'LoginTop'
  },
  {
    from: 'OwnerPatientsInformation',
    to: 'ReservationMenu'
  },
  {
    from: 'OwnerPatientsInformation',
    to: 'LoginTop'
  },
  {
    from: 'OwnerPatientsInformation',
    to: 'OwnerPatientsInformationChange'
  },
  {
    from: 'OwnerPatientsInformationChange',
    to: 'ReservationMenu'
  },
  {
    from: 'OwnerPatientsInformationChange',
    to: 'LoginTop'
  },
  {
    from: 'OwnerPatientsInformationChange',
    to: 'OwnerPatientsInformationConfirm'
  },
  {
    from: 'OwnerPatientsInformationChange',
    to: 'OwnerPatientsInformation'
  },
  {
    from: 'OwnerPatientsInformationConfirm',
    to: 'ReservationMenu'
  },
  {
    from: 'OwnerPatientsInformationConfirm',
    to: 'LoginTop'
  },
  {
    from: 'OwnerPatientsInformationConfirm',
    to: 'OwnerPatientsInformationCompletion'
  },
  {
    from: 'OwnerPatientsInformationConfirm',
    to: 'OwnerPatientsInformationChange'
  },
  {
    from: 'OwnerPatientsInformationCompletion',
    to: 'ReservationMenu'
  },
  {
    from: 'OwnerPatientsInformationCompletion',
    to: 'LoginTop'
  },
  {
    from: 'PasswordResetSendMail',
    to: 'LoginTop'
  },
  {
    from: 'PasswordResetSendMail',
    to: 'PasswordResetSendAfter'
  },
  {
    from: 'PasswordResetSendAfter',
    to: 'LoginTop'
  },
  {
    from: 'PasswordReset',
    to: 'PasswordResetRegistered'
  },
  {
    from: 'PasswordReset',
    to: 'LoginTop'
  },
  {
    from: 'PasswordResetRegistered',
    to: 'ReservationMenu'
  },
  {
    from: 'PasswordResetRegistered',
    to: 'LoginTop'
  }
]

export const preventForward = (from, to) => {
  const scenario = store.getters['auth/scenario']
  const reservation =
    scenario === 'change_reservation'
      ? store.getters['reservation/getChangeReservation']
      : store.getters['reservation/getNewReservation']
  const invalidFlg = store.getters['petorelu/get'].invalidFlg
  return (from.name === 'LoginTop' &&
    to.name === 'ReservationPurpose' &&
    scenario === '') ||
    (from.name === 'ReservationDateSelect' &&
      to.name === 'ReservationTimeSelect' &&
      reservation.date === '') ||
    (from.name === 'ReservationTimeSelect' &&
      to.name === 'FirstReservationInputPage' &&
      reservation.startTime === '') ||
    (from.name === 'FirstReservationInputPage' &&
      to.name === 'RegistrationConfirm' &&
      invalidFlg) ||
    (from.name === 'ReservationTimeSelect' &&
      to.name === 'ReservationInputPage' &&
      reservation.startTime === '') ||
    (from.name === 'ReservationInputPage' &&
      to.name === 'RegistrationConfirmChangeReservation' &&
      invalidFlg) ||
    (from.name === 'ReservationConfirm' &&
      to.name === 'ReservationPurpose' &&
      scenario === '') ||
    (from.name === 'OwnerPatientsInformationChange' &&
      to.name === 'OwnerPatientsInformationConfirm' &&
      invalidFlg)
    ? true
    : false
}

export const isNgTransition = (from, to) => {
  if (from.name === null) return false
  if (preventForward(from, to)) return true
  return !okTransitionList.some(v => v.from === from.name && v.to === to.name)
}
