export default {
  en: {
    pageTitle: 'Reset Password',
    popupMessageNotRegistered: 'Email address is not registered.',
    popupMessageUnableSend: 'Unable to send email.',
    textEnterEmail: 'Enter the email address you registered with.'
  },
  ja: {
    pageTitle: 'パスワードの発行/再設定',
    popupMessageNotRegistered: 'メールアドレスが登録されていません。',
    popupMessageUnableSend: 'メールの送信ができません。',
    textEnterEmail: 'ご登録いただいたメールアドレスを入力してください。'
  }
}
