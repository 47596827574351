<template>
  <div class="notification-list-item-title">
    <div class="notification-list-item-title-text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationListItemTitle'
}
</script>

<style lang="scss" scoped>
.notification-list-item-title {
  > .notification-list-item-title-text {
    height: 33px;
    @include font-size(20);
    font-weight: bold;
    line-height: 1.45;
    border-left: solid 14px #cecece;
    text-align: left;
    color: #{$plus_black};
    padding-left: 20px;
    display: flex;
    align-items: center;
  }
}
</style>
