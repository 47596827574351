<template>
  <div class="login-top">
    <div class="error" v-if="errorFlg">
      <notification-list-item class="message">
        <template #title>お知らせ</template>
        <template #content>{{
          'このアドレスは現在使われておりません。\n該当の医院に直接連絡の上ご確認ください。'
        }}</template>
      </notification-list-item>
      <notification-list-item class="message">
        <template #title>Notice</template>
        <template #content>{{
          'This address is currently not used. \nPlease contact the relevant clinic directly for confirmation.'
        }}</template>
      </notification-list-item>
    </div>
    <div class="success" v-else>
      <div class="page-title">
        <div class="page-title-area">
          <img
            v-if="clinic.image === null"
            src="@/assets/base_page_title_login_top.png"
            class="page-title-img"
            width="40px"
            height="40px"
            alt="page-title"
          />
          <img
            v-else
            :src="clinic.image"
            class="clinic-img"
            width="40px"
            height="40px"
            alt="page-title"
          />
          <div class="page-title-text">
            <base-page-title>{{ clinicName }}</base-page-title>
          </div>
        </div>
      </div>
      <div class="text-input-mail">
        <div class="label-mail">{{ $t('loginTop.email') }}</div>
        <base-text-input-mail v-model="email" />
      </div>
      <div class="text-input-password">
        <div class="label-password">{{ $t('loginTop.password') }}</div>
        <base-text-input-password v-model="password" />
      </div>
      <div class="login-button">
        <base-login-button @click="trySignIn" :disabled="waitFlg" />
      </div>
      <div class="link-text-forget-password">
        <base-for-guest-button @click="pushToPasswordResetSendMail">
          {{ $t('loginTop.forgetPassword') }}
        </base-for-guest-button>
      </div>
      <div class="link-text-forget-password-under-640px-width">
        <base-for-guest-button @click="pushToPasswordResetSendMail">
          {{ $t('loginTop.forgetPassword640px') }}
        </base-for-guest-button>
      </div>
      <div
        class="for-guest-button"
        v-if="
          reservationSetting.netAvailableFlg &&
            reservationSetting.firstAvailableFlg
        "
      >
        <base-for-guest-button @click="pushToReservationPurpose">{{
          $t('loginTop.forGuestButton')
        }}</base-for-guest-button>
      </div>
      <div
        v-if="!reservationSetting.netAvailableFlg"
        class="net-unavailable-message"
      >
        {{ $t('loginTop.netUnavailableMessage') }}
      </div>
      <div class="link-text-about-reservation">
        <!--
        <base-link-text-about-reservation />
        -->
      </div>
      <div class="top-notification-list">
        <notification-list
          :notification="reservationSetting.netNotice"
          :notificationEnglish="reservationSetting.netNoticeEnglish"
          :clinicName="clinicName"
          :clinic="clinic"
        />
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      @close="closeAlert"
      :title="$t('loginTop.popupTitle')"
      :buttons="[$t('common.close')]"
      :type="'failure'"
      >{{ $t(`${popupMessage}`) }}</announce-popup
    >
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import BaseTextInputMail from '@/components/parts/atoms/BaseTextInputMail.vue'
import BaseTextInputPassword from '@/components/parts/atoms/BaseTextInputPassword.vue'
import BaseLoginButton from '@/components/parts/atoms/BaseLoginButton'
import BaseForGuestButton from '@/components/parts/atoms/BaseForGuestButton.vue'
//import BaseLinkTextAboutReservation from '@/components/parts/atoms/BaseLinkTextAboutReservation.vue'
import NotificationList from '@/components/parts/organisms/NotificationList.vue'
import NotificationListItem from '@/components/parts/molecules/NotificationListItem.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'

import { axiosObjectForNewLogin } from '@/utils/library'
import { logout, getClinicData } from '@/components/mixins/ApiReset'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginTop',

  components: {
    BasePageTitle,
    BaseTextInputMail,
    BaseTextInputPassword,
    BaseLoginButton,
    BaseForGuestButton,
    //BaseLinkTextAboutReservation,
    NotificationList,
    NotificationListItem,
    AnnouncePopup
  },

  data() {
    return {
      email: process.env.VUE_APP_DEV_EMAIL,
      password: process.env.VUE_APP_DEV_PASSWORD,
      popupMessage: '',
      errorFlg: false,
      alertFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getClinic',
      reservationSetting: 'reservationSetting/getReservationSetting',
      scenario: 'auth/scenario',
      logined: 'auth/logined',
      petorelu: 'petorelu/get'
    }),
    clinicName() {
      return this.$i18n.locale === 'en'
        ? this.clinic.nameEnglish || this.clinic.name
        : this.clinic.name
    }
  },

  async created() {
    logout()
    if (!this.$store.getters['auth/token']) {
      this.errorFlg = !(await getClinicData(this.$route.query.clinic))
    }
  },

  methods: {
    async handleSignIn(email, password) {
      try {
        const res = await axiosObjectForNewLogin().post('/login', {
          email,
          password,
          isLanguageChanged: this.petorelu.isLanguageChanged,
          englishFlg: this.$i18n.locale === 'en' ? 1 : 0
        })
        this.$store.dispatch('auth/authLogin', res.data.token)
        this.$store.dispatch('owner/addOwner', res.data.owner)
        this.$store.dispatch('patient/addPatients', res.data.patients)
        this.$store.dispatch(
          'reservation/setReservations',
          res.data.reservations
        )
        this.$store.dispatch(
          'reservationRequests/setData',
          res.data.reservationRequests
        )
        this.$store.dispatch('master/setData', res.data.master)
        this.setLanguage(this.reservationSetting, res.data.owner)
        return true
      } catch (ex) {
        return ex.response?.status === 401 ? 'not authenticated' : false
      }
    },
    async trySignIn() {
      this.waitFlg = true
      const password = this.password?.trim()
      const res = await this.handleSignIn(this.email, password)
      if (res === true) {
        this.$router.push({ name: 'ReservationMenu' })
      } else {
        this.alertFlg = true
        this.waitFlg = false
        if (res === 'not authenticated') {
          this.popupMessage = 'loginTop.popupMessageNotAuthenticated'
        } else {
          this.popupMessage = 'loginTop.popupMessageBadCredentials'
        }
      }
    },
    closeAlert() {
      this.alertFlg = false
    },
    pushToPasswordResetSendMail() {
      this.$router.push({
        name: 'PasswordResetSendMail',
        query: { clinic: this.$route.query.clinic }
      })
    },
    pushToReservationPurpose() {
      this.$store.dispatch('auth/firstReservationScenario')
      this.$router.push({ name: 'ReservationPurpose' })
    },
    setLanguage(reservationSetting, owner) {
      this.$i18n.locale =
        reservationSetting.englishOptionFlg && owner.englishFlg ? 'en' : 'ja'
    }
  }
}
</script>

<style lang="scss" scoped>
.login-top {
  white-space: pre;
  margin-bottom: 100px;
  > .error {
    margin: 20px;
  }
  > .success {
    > .page-title {
      @include clearfix;
      width: 95%;
      margin: 70px auto 0;
      > .page-title-area {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        > .page-title-img {
          width: 40px;
          float: left;
          margin-top: 3px;
          margin-right: 10px;
        }
        > .clinic-img {
          width: 40px;
          float: left;
          margin-top: 3px;
          margin-right: 10px;
          border-radius: 50%;
        }
        > .page-title-text {
          float: left;
        }
      }
    }
    > .text-input-mail {
      margin-top: 28px;
      > .label-mail {
        text-align: left;
        width: 410px;
        margin: 0 auto 10px;
        font-weight: bold;
      }
    }
    > .text-input-password {
      margin-top: 16px;
      > .label-password {
        text-align: left;
        width: 410px;
        margin: 0 auto 10px;
        font-weight: bold;
      }
    }
    > .login-button {
      margin-top: 56px;
    }
    > .link-text-forget-password {
      margin-top: 30px;
    }
    > .link-text-forget-password-under-640px-width {
      display: none;
    }
    > .for-guest-button {
      margin-top: 30px;
    }
    > .net-unavailable-message {
      margin-top: 50px;
      font-weight: bold;
      @include font-size(18);
    }
    > .link-text-about-reservation {
      margin-top: 16px;
    }
    > .top-notification-list {
      margin-top: 100px;
    }
  }
}
$width: 410px;
@media (max-width: $global-container) {
  .login-top {
    white-space: normal;
    > .success {
      > .page-title {
        margin-top: 63px;
      }
      > .text-input-mail {
        margin-top: 25px;
        > .label-mail {
          max-width: $width;
          width: calc-percent($width, 640px);
        }
      }
      > .text-input-password {
        > .label-password {
          max-width: $width;
          width: calc-percent($width, 640px);
        }
      }
      > .link-text-forget-password {
        display: none;
      }
      > .link-text-forget-password-under-640px-width {
        display: block;
        margin-top: 30px;
      }
      > .net-unavailable-message {
        max-width: 550px;
        width: calc-percent(550px, 640px);
        margin: 50px auto 0;
      }
    }
  }
}
</style>
