<template>
  <div class="base-page-title">
    <span class="page-title"><slot /></span>
  </div>
</template>

<script>
export default {
  name: 'BasePageTitle'
}
</script>

<style lang="scss" scoped>
.base-page-title {
  > .page-title {
    @include font-size(30);
    font-weight: bold;
  }
}
</style>
