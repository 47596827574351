import store from '@/store/store'
import { axiosObjectForReload } from '@/utils/library'
import { i18n } from '@/i18n'

export async function handleReload(token) {
  try {
    const res = await axiosObjectForReload(token).post('/reload')
    store.dispatch('auth/authLogin', res.data.token)
    store.dispatch('owner/addOwner', res.data.owner)
    store.dispatch('patient/addPatients', res.data.patients)
    store.dispatch('reservation/setReservations', res.data.reservations)
    store.dispatch('reservationRequests/setData', res.data.reservationRequests)
    store.dispatch('clinic/addClinic', res.data.clinic)
    store.dispatch(
      'reservationSetting/addReservationSetting',
      res.data.reservationSetting
    )
    store.dispatch(
      'reservationPurpose/addReservationPurposes',
      res.data.reservationPurposes
    )
    store.dispatch('species/addSpecies', res.data.species)
    store.dispatch('auth/setBackVersion', res.data.version)
    store.dispatch('master/setData', res.data.master)
    setLanguage(res.data.reservationSetting, res.data.owner)
    return true
  } catch (error) {
    return false
  }
}

const setLanguage = (reservationSetting, owner) => {
  i18n.locale =
    reservationSetting.englishOptionFlg && owner.englishFlg ? 'en' : 'ja'
}

export default {
  methods: {
    handleReload
  }
}
