import common from '@/langs/common.json'
import parts from '@/langs/parts.json'
import utils from '@/langs/utils'
import app from '@/langs/App'
import firstReservationInputPage from '@/langs/FirstReservationInputPage'
import loginTop from '@/langs/LoginTop'
import ownerPatientsInformation from '@/langs/OwnerPatientsInformation'
import ownerPatientsInformationChange from '@/langs/OwnerPatientsInformationChange'
import ownerPatientsInformationCompletion from '@/langs/OwnerPatientsInformationCompletion'
import ownerPatientsInformationConfirm from '@/langs/OwnerPatientsInformationConfirm'
import passwordReset from '@/langs/PasswordReset'
import passwordResetRegistered from '@/langs/PasswordResetRegistered'
import passwordResetSendAfter from '@/langs/PasswordResetSendAfter'
import passwordResetSendMail from '@/langs/PasswordResetSendMail'
import registrationConfirmChangeReservation from '@/langs/RegistrationConfirmChangeReservation.js'
import reservationCancelComplete from '@/langs/ReservationCancelComplete'
import reservationConfirm from '@/langs/ReservationConfirm'
import reservationDateSelect from '@/langs/ReservationDateSelect'
import reservationMenu from '@/langs/ReservationMenu'
import reservationPurpose from '@/langs/ReservationPurpose'
import reservationRegistered from '@/langs/ReservationRegistered'
import reservationTimeSelect from '@/langs/ReservationTimeSelect'
import sendMailFirstReservation from '@/langs/SendMailFirstReservation'

// 英語対応終了後 @/langs/common.js に統合して削除
import commonTmp from '@/langs/common_tmp'

export default {
  en: {
    common: { ...common.en, ...commonTmp.en },
    parts: parts.en,
    utils: utils.en,
    app: app.en,
    firstReservationInputPage: firstReservationInputPage.en,
    loginTop: loginTop.en,
    ownerPatientsInformation: ownerPatientsInformation.en,
    ownerPatientsInformationChange: ownerPatientsInformationChange.en,
    ownerPatientsInformationCompletion: ownerPatientsInformationCompletion.en,
    ownerPatientsInformationConfirm: ownerPatientsInformationConfirm.en,
    passwordReset: passwordReset.en,
    passwordResetRegistered: passwordResetRegistered.en,
    passwordResetSendAfter: passwordResetSendAfter.en,
    passwordResetSendMail: passwordResetSendMail.en,
    registrationConfirmChangeReservation:
      registrationConfirmChangeReservation.en,
    reservationCancelComplete: reservationCancelComplete.en,
    reservationConfirm: reservationConfirm.en,
    reservationDateSelect: reservationDateSelect.en,
    reservationMenu: reservationMenu.en,
    reservationPurpose: reservationPurpose.en,
    reservationRegistered: reservationRegistered.en,
    reservationTimeSelect: reservationTimeSelect.en,
    sendMailFirstReservation: sendMailFirstReservation.en
  },
  ja: {
    common: { ...common.ja, ...commonTmp.ja },
    parts: parts.ja,
    utils: utils.ja,
    app: app.ja,
    firstReservationInputPage: firstReservationInputPage.ja,
    loginTop: loginTop.ja,
    ownerPatientsInformation: ownerPatientsInformation.ja,
    ownerPatientsInformationChange: ownerPatientsInformationChange.ja,
    ownerPatientsInformationCompletion: ownerPatientsInformationCompletion.ja,
    ownerPatientsInformationConfirm: ownerPatientsInformationConfirm.ja,
    passwordReset: passwordReset.ja,
    passwordResetRegistered: passwordResetRegistered.ja,
    passwordResetSendAfter: passwordResetSendAfter.ja,
    passwordResetSendMail: passwordResetSendMail.ja,
    registrationConfirmChangeReservation:
      registrationConfirmChangeReservation.ja,
    reservationCancelComplete: reservationCancelComplete.ja,
    reservationConfirm: reservationConfirm.ja,
    reservationDateSelect: reservationDateSelect.ja,
    reservationMenu: reservationMenu.ja,
    reservationPurpose: reservationPurpose.ja,
    reservationRegistered: reservationRegistered.ja,
    reservationTimeSelect: reservationTimeSelect.ja,
    sendMailFirstReservation: sendMailFirstReservation.ja
  }
}
