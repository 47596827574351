const state = {
  species: []
}

const mutations = {
  setData(state, species) {
    state.species = species
  }
}

const getters = {
  getData(state) {
    return state.species
      .filter(v => v.delFlg === 0)
      .sort((a, b) => {
        return a.order - b.order
      })
  },
  getDataIncludeDelData(state) {
    return state.species
  }
}

const actions = {
  addSpecies({ commit }, species) {
    return commit('setData', species)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
