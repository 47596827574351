export default {
  en: {
    buttonAddPatient: 'Add',
    buttonAutoFillAddress: 'Auto-fill Address',
    buttonChange: 'Change',
    buttonClose: 'close',
    buttonConfirmChanges: 'Confirm changes',
    buttonGoToLogin: 'Go to login screen',
    buttonMenu: 'Menu',
    buttonModifyChanges: 'Modify changes',
    buttonNext: 'Next',
    buttonRefresh: 'Refreshing the page',
    buttonReturn: 'Return',
    buttonSubmit: 'Send',
    buttonToLogin: 'Go to login screen',
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
    error: 'Error',
    labelAddress: 'Address',
    labelAnimalType: 'Animal type',
    labelBreed: 'Breed',
    labelBuildingApartment: 'Building or Apartment Name',
    labelCityTownVillage: 'City, Town, or Village',
    labelDateRequested: 'Date requested',
    labelEmail: 'Email address',
    labelFirstName: 'First name',
    labelJaBrackets: '',
    labelKatakana: 'Name in Katakana',
    labelLastName: 'Last name',
    labelName: 'Name',
    labelNameKana: 'Name in Katakana',
    labelPassword: 'Password',
    labelPasswordReEnter: 'Password (re-enter)',
    labelPetName: 'Pet name',
    labelPhoneNumber: 'Phone number',
    labelPostalCode: 'Postal code',
    labelPrefecture: 'Prefecture',
    labelPurposeOfVisit: 'Purpose of visit',
    labelRemarks: 'Remarks',
    ownerName: '{first} {last}',
    placeholderBreed: 'Breed',
    placeholderFirstName: 'John',
    placeholderFirstNameKana: 'ジョン',
    placeholderLastName: 'Smith',
    placeholderLastNameKana: 'スミス',
    placeholderPassword: 'Alphanumeric symbols 8 to 100 characters',
    placeholderPetName: 'Name',
    placeholderPostalCode: '1234567',
    placeholderStringLength: 'Up to {length} characters',
    popupMessageServerErrorReloadPage:
      'A server error has occurred.\nPlease refresh the page and submit your reservation again.',
    popupMessageServerErrorWait:
      'A server error has occurred.\nPlease try again after a while.',
    progressBarAuthentication: 'Authentication',
    progressBarConfirm: 'Confirmation',
    progressBarEnterEmail: 'Email address',
    progressBarFinish: 'Confirmed',
    progressBarModifyOwner: 'Modify information',
    progressBarNewPassword: 'New password',
    reservationDate: 'Date and time',
    textIfNoEmail: 'Please call us in the case you have not received any email.'
  },
  ja: {
    buttonAddPatient: '患者様追加',
    buttonAutoFillAddress: '住所自動入力',
    buttonChange: '変更する',
    buttonClose: '閉じる',
    buttonConfirmChanges: '変更を確定する',
    buttonGoToLogin: 'ログイン画面へ',
    buttonMenu: 'メニューへ',
    buttonModifyChanges: '変更内容を修正する',
    buttonNext: '次へ',
    buttonRefresh: '再読み込み',
    buttonReturn: 'もどる',
    buttonSubmit: '送信',
    buttonToLogin: 'ログイン画面へ',
    cancel: 'キャンセル',
    close: '閉じる',
    delete: '削除',
    error: '失敗',
    labelAddress: '住所',
    labelAnimalType: '種別',
    labelBreed: '品種',
    labelBuildingApartment: 'ビル・マンション名',
    labelCityTownVillage: '市区町村',
    labelDateRequested: '希望日時',
    labelEmail: 'メールアドレス',
    labelFirstName: '名',
    labelJaBrackets: '（{str}）',
    labelKatakana: 'フリガナ',
    labelLastName: '姓',
    labelName: '氏名',
    labelNameKana: '氏名',
    labelPassword: 'パスワード',
    labelPasswordReEnter: 'パスワード（再入力）',
    labelPetName: '患者様名',
    labelPhoneNumber: '電話番号',
    labelPostalCode: '郵便番号',
    labelPrefecture: '都道府県',
    labelPurposeOfVisit: '来院目的',
    labelRemarks: '備考',
    ownerName: '{last}　{first}{honorific}',
    placeholderBreed: 'ひんしゅ',
    placeholderFirstName: '太郎',
    placeholderFirstNameKana: 'タロウ',
    placeholderLastName: '飼主',
    placeholderLastNameKana: 'カイヌシ',
    placeholderPassword: '半角英数字記号8～100文字',
    placeholderPetName: 'おなまえ',
    placeholderPostalCode: '1234567',
    placeholderStringLength: '{length}文字まで',
    popupMessageServerErrorReloadPage:
      'サーバーエラーが発生しました。\nページを更新して再度予約登録を行ってください。',
    popupMessageServerErrorWait:
      'サーバーエラーが発生しました。\n暫くしてから再度お試し下さい。',
    progressBarAuthentication: '本人確認',
    progressBarConfirm: '確認',
    progressBarEnterEmail: 'メールアドレス入力',
    progressBarFinish: '完了',
    progressBarModifyOwner: '飼主様情報変更',
    progressBarNewPassword: 'パスワード設定',
    reservationDate: '予約日時',
    textIfNoEmail: 'メールが届いていない場合、電話にてお問い合わせ下さい。'
  }
}
