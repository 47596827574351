const state = {
  reservationPurposes: []
}

const mutations = {
  setData(state, reservationPurposes) {
    state.reservationPurposes = reservationPurposes
  }
}

const getters = {
  getReservationPurposes(state) {
    return state.reservationPurposes.sort((a, b) => a.order - b.order)
  },
  getReservationPurposeById: state => id => {
    return state.reservationPurposes.find(v => v.id === id)
  }
}

const actions = {
  addReservationPurposes({ commit }, reservationPurposes) {
    return commit('setData', reservationPurposes)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
