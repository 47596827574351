<template>
  <div class="notification-list">
    <div class="notification-list-whole">
      <notification-list-item id="notification" v-if="notification">
        <template #title>{{ $t('parts.notification') }}</template>
        <template #content>{{ displayNotification }}</template>
      </notification-list-item>
      <notification-list-item
        id="emergency-contact"
        v-if="clinic.contactTelShowFlg === 1"
      >
        <template #title>{{ $t('parts.emergencyContact') }}</template>
        <template #content>{{
          clinicName + '\u{3000}' + clinic.contactTel
        }}</template>
      </notification-list-item>
      <notification-list-item id="email-receiving-permission-setting">
        <template #title>{{
          $t('parts.emailReceivingPermissionSettingTitle')
        }}</template>
        <template #content>{{
          $t('parts.emailReceivingPermissionSettingMessage')
        }}</template>
      </notification-list-item>
    </div>
  </div>
</template>

<script>
import NotificationListItem from '@/components/parts/molecules/NotificationListItem.vue'

export default {
  name: 'NotificationList',

  components: {
    NotificationListItem
  },

  filters: {
    addFullWideSpace(text) {
      return text + `\u{3000}`
    }
  },

  props: {
    notification: { type: String, default: '' },
    notificationEnglish: { type: String, default: '' },
    clinicName: { type: String, default: '' },
    clinic: { type: Object }
  },
  computed: {
    displayNotification() {
      return this.$i18n.locale === 'en'
        ? this.notificationEnglish || this.notification
        : this.notification
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-list {
  > .notification-list-whole {
    width: 570px;
    margin: 0 auto;
  }
}
@media (max-width: 640px) {
  .notification-list {
    @include selector-width('.notification-list-whole', 550px);
  }
}
</style>
