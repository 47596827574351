const state = {
  clinic: {}
}

const mutations = {
  setData(state, clinic) {
    if (clinic.image !== null) {
      clinic.image = Buffer.from(clinic.image, 'base64').toString()
    }
    state.clinic = clinic
  }
}

const getters = {
  getClinic(state) {
    return state.clinic
  }
}

const actions = {
  addClinic({ commit }, clinic) {
    return commit('setData', clinic)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
