import store from '@/store/store'
import axios from 'axios'

export const getApiUrl = () => {
  return process.env.VUE_APP_API_URL + '/api'
}

export const authHeaders = () => {
  const scenario = store.getters['auth/scenario']
  if (scenario === 'first_reservation') {
    const entranceToken = store.getters['auth/entranceToken']
    return {
      headers: {
        Authorization: 'Bearer ' + entranceToken,
        'Content-Type': 'application/json',
        'Net-Version': process.env.VERSION
      }
    }
  } else {
    const token = store.getters['auth/token']
    return {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Net-Version': process.env.VERSION
      }
    }
  }
}

export const getAxiosObject = () => {
  const axiosObject = axios.create({
    baseURL: getApiUrl(),
    headers: authHeaders().headers
  })
  axiosObject.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        window.location.hash = '#/maintenance'
      } else if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        store.dispatch('petorelu/setNetVersionUpFlgToTrue')
      }
      throw error
    }
  )
  return axiosObject
}

// router/index.jsのテスト時、gettersの処理をモック化するため関数にしました。
export const netAvailableFlg = () => {
  return store.getters['reservationSetting/getReservationSetting']
    .netAvailableFlg
}

export const axiosObjectForNewLogin = () => {
  const token = store.getters['auth/entranceToken']
  const axiosObject = axios.create({
    baseURL: getApiUrl(),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Net-Version': process.env.VERSION
    }
  })
  axiosObject.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        window.location.hash = '#/maintenance'
      } else if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        store.dispatch('petorelu/setNetVersionUpFlgToTrue')
      }
      throw error
    }
  )
  return axiosObject
}

export const axiosObjectForReload = token => {
  const axiosObject = axios.create({
    baseURL: getApiUrl(),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Net-Version': process.env.VERSION
    }
  })
  axiosObject.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        window.location.hash = '#/maintenance'
      } else if (
        error.response?.status === 400 &&
        error.response?.data.extra === 'not the same as required version'
      ) {
        store.dispatch('petorelu/setNetVersionUpFlgToTrue')
      }
      throw error
    }
  )
  return axiosObject
}
