const state = {
  patients: [],
  key: 1,
  newPatients: [],
  changePatients: [],
  delPatientsIds: []
}

const mutations = {
  // plus_backのデータと同期する
  setData(state, patients) {
    state.patients = patients
  },
  removeData(state) {
    state.patients = []
  },

  setNewPatients(state, patients) {
    state.newPatients = patients
  },
  resetNewPatients(state) {
    state.newPatients = []
  },

  // patientsの変更時用、飼主・患者様の変更画面で使用
  inputChangePatients(state, obj) {
    state.changePatients = obj.patients
    state.delPatientsIds = obj.delPatientsIds
  },
  setChangePatientsData(state) {
    state.changePatients = state.patients.map(obj => Object.assign({}, obj))
    let num = 1
    state.changePatients.map(obj => {
      obj['key'] = num++
    })
  },

  setDelPatientsIdsData(state) {
    state.delPatientsIds = []
  }
}

const getters = {
  getPatients(state) {
    return state.patients
  },
  getChangePatients(state) {
    return state.changePatients
  },
  getNewPatients(state) {
    return state.newPatients
  },
  getDelPatientsIds(state) {
    return state.delPatientsIds
  }
}

const actions = {
  // plus_backのデータと同期する
  addPatients({ commit }, patients) {
    return commit('setData', patients)
  },
  removeData({ commit }) {
    return commit('removeData')
  },

  // patientsの新規登録時用
  setNewPatients({ commit }, patients) {
    return commit('setNewPatients', patients)
  },
  resetNewPatients({ commit }) {
    return commit('resetNewPatients')
  },

  // patientsの変更時用、飼主・患者様の変更画面で使用
  inputChangePatients({ commit }, obj) {
    return commit('inputChangePatients', obj)
  },
  // state.patientsのデータをコピーして入れる
  setChangePatients({ commit }) {
    return commit('setChangePatientsData')
  },

  setDelPatientsIds({ commit }) {
    return commit('setDelPatientsIdsData')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
