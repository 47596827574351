<template>
  <div class="notification-list-item">
    <div class="notification-list-item-box">
      <notification-list-item-title>
        <template #text>
          <slot name="title"></slot>
        </template>
      </notification-list-item-title>
      <notification-list-item-content>
        <template #text>
          <slot name="content"></slot>
        </template>
      </notification-list-item-content>
    </div>
  </div>
</template>

<script>
import NotificationListItemTitle from '@/components/parts/atoms/NotificationListItemTitle.vue'
import NotificationListItemContent from '@/components/parts/atoms/NotificationListItemContent.vue'

export default {
  name: 'NotificationListItem',

  components: {
    NotificationListItemTitle,
    NotificationListItemContent
  }
}
</script>

<style lang="scss" scoped>
.notification-list-item {
  > .notification-list-item-box {
    margin-bottom: 24px;
    white-space: pre-line;
    word-wrap: break-word;
  }
}
</style>
