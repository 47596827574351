export default {
  en: {
    enterEmailAddress: 'Email address',
    error: 'Error',
    finish: 'Confirmation',
    passwordRegistrationFailure: 'Password registration failed.',
    resetPasswordTopic: 'Reset Password',
    setNewPassword: 'New password',
    setNewPasswordNotice: 'Please set a new password.',
    userAuthentication: 'Authentication'
  },
  ja: {
    enterEmailAddress: 'メールアドレス入力',
    error: '失敗',
    finish: '完了',
    passwordRegistrationFailure: 'パスワードの登録に失敗しました',
    resetPasswordTopic: 'パスワードの発行/再設定',
    setNewPassword: 'パスワード設定',
    setNewPasswordNotice: '新しいパスワードを設定してください。',
    userAuthentication: '本人確認'
  }
}
