import { getAxiosObject } from '@/utils/library'
const state = {
  reservations: [],
  newReservation: {
    reservationPurposeId: 0,
    date: '',
    startTime: '',
    memo: '',
    patientsIds: []
  },
  changeReservation: {},
  cancelReservation: {}
}

const mutations = {
  // state.xxx.yyy = zzz
  set(state, { type, dataName, data }) {
    switch (type) {
      case 'reservations':
        state.reservations = data
        break
      case 'newReservation':
        if (dataName) {
          state.newReservation[dataName] = data
        } else {
          state.newReservation = data
        }
        break
      case 'changeReservation':
        if (dataName) {
          state.changeReservation[dataName] = data
        } else {
          state.changeReservation = data
        }
        break
      case 'cancelReservation':
        state.cancelReservation = data
        break
      default:
        //if not matching any of the above cases, do nothing
        break
    }
  }
}

const getters = {
  getReservations(state) {
    return state.reservations
  },
  getNewReservation(state) {
    return state.newReservation
  },
  getChangeReservation(state) {
    return state.changeReservation
  },
  getCancelReservation(state) {
    return state.cancelReservation
  },
  getReservationById: state => id => {
    return state.reservations.find(v => v.id === id)
  }
}

const actions = {
  // reservations
  setReservations({ commit }, reservations) {
    commit('set', { type: 'reservations', dataName: '', data: reservations })
  },
  resetReservations({ commit }) {
    commit('set', { type: 'reservations', dataName: '', data: [] })
  },
  // newReservation
  setNewReservation({ commit }, reservation) {
    commit('set', { type: 'newReservation', dataName: '', data: reservation })
  },
  setNewReservationPurposeId({ commit }, selectReservationPurposeId) {
    commit('set', {
      type: 'newReservation',
      dataName: 'reservationPurposeId',
      data: selectReservationPurposeId
    })
  },
  setNewReservationDate({ commit }, date) {
    commit('set', { type: 'newReservation', dataName: 'date', data: date })
  },
  setNewReservationTime({ commit }, time) {
    commit('set', {
      type: 'newReservation',
      dataName: 'startTime',
      data: time
    })
  },
  resetNewReservation({ commit }) {
    commit('set', {
      type: 'newReservation',
      dataName: 'reservationPurposeId',
      data: 0
    })
    commit('set', { type: 'newReservation', dataName: 'date', data: '' })
    commit('set', { type: 'newReservation', dataName: 'startTime', data: '' })
    commit('set', { type: 'newReservation', dataName: 'memo', data: '' })
    commit('set', {
      type: 'newReservation',
      dataName: 'patientsIds',
      data: []
    })
  },
  // changeReservation
  setChangeReservation({ commit }, reservation) {
    commit('set', {
      type: 'changeReservation',
      dataName: '',
      data: reservation
    })
  },
  setChangeReservationPurpose({ commit }, selectReservationPurpose) {
    commit('set', {
      type: 'changeReservation',
      dataName: 'reservationPurposeId',
      data: selectReservationPurpose.id
    })
    commit('set', {
      type: 'changeReservation',
      dataName: 'reservationPurposeName',
      data: selectReservationPurpose.name
    })
    commit('set', {
      type: 'changeReservation',
      dataName: 'reservationPurposeNameEnglish',
      data: selectReservationPurpose.nameEnglish
    })
  },
  setChangeReservationDate({ commit }, date) {
    commit('set', { type: 'changeReservation', dataName: 'date', data: date })
  },
  setChangeReservationTime({ commit }, time) {
    commit('set', {
      type: 'changeReservation',
      dataName: 'startTime',
      data: time
    })
  },
  setChangeReservationMemo({ commit }, memo) {
    commit('set', { type: 'changeReservation', dataName: 'memo', data: memo })
  },
  resetChangeReservation({ commit }) {
    commit('set', { type: 'changeReservation', dataName: '', data: {} })
  },
  // newReservation & changeReservation
  resetDate({ commit }) {
    commit('set', { type: 'newReservation', dataName: 'date', data: '' })
    commit('set', { type: 'changeReservation', dataName: 'date', data: '' })
  },
  resetStartTime({ commit }) {
    commit('set', { type: 'newReservation', dataName: 'startTime', data: '' })
    commit('set', {
      type: 'changeReservation',
      dataName: 'startTime',
      data: ''
    })
  },
  // cancelReservation
  resetCancelReservation({ commit }) {
    commit('set', { type: 'cancelReservation', dataName: '', data: {} })
  },
  async cancelReservation({ commit }, cancelReservation) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/reservations/cancel', {
        reservationsIds: cancelReservation.ids
      })
      if (res.status === 200) {
        commit('set', {
          type: 'reservations',
          dataName: '',
          data: res.data.reservations
        })
        commit('set', {
          type: 'cancelReservation',
          dataName: '',
          data: cancelReservation
        })
        return true
      }
    } catch (ex) {
      if (ex.response?.status === 401) return 'not authenticated'
      else if (ex.response?.data) return ex.response.data
      else return false
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
