const state = {
  owner: {},
  newOwner: {
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    tel: '',
    email: '',
    password: '',
    postalCode: '',
    prefectureId: 0,
    address: '',
    building: '',
    reEnterPassword: ''
  },
  changeOwner: {}
}

const mutations = {
  // plus_backのデータと同期する
  setData(state, owner) {
    state.owner = owner
  },
  removeData(state) {
    state.owner = {}
  },

  // ownerの新規作成時用
  setNewOwner(state, owner) {
    state.newOwner = owner
  },
  resetNewOwner(state) {
    state.newOwner = {
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      tel: '',
      email: '',
      password: '',
      postalCode: '',
      prefectureId: 0,
      address: '',
      building: '',
      reEnterPassword: ''
    }
  },

  // ownerの変更時用、飼主・患者様の変更画面で使用
  inputChangeOwner(state, owner) {
    state.changeOwner = owner
  },
  setChangeOwnerData(state) {
    // idと画面で変更できる値だけに絞る。
    // 下記のカラム以外の情報（showIdなど）は変更されないようにする。
    const inputKeys = [
      'id',
      'firstName',
      'lastName',
      'firstNameKana',
      'lastNameKana',
      'tel',
      'email',
      'prefectureId',
      'postalCode',
      'address',
      'building'
    ]
    inputKeys.forEach(key => {
      state.changeOwner[key] = state.owner[key]
    })
  }
}

const getters = {
  getOwner(state) {
    return state.owner
  },
  getChangeOwner(state) {
    return state.changeOwner
  },
  getNewOwner(state) {
    return state.newOwner
  }
}

const actions = {
  // plus_backのデータと同期する
  addOwner({ commit }, owner) {
    return commit('setData', owner)
  },
  removeData({ commit }) {
    return commit('removeData')
  },
  // ownerの新規作成時用
  setNewOwner({ commit }, owner) {
    return commit('setNewOwner', owner)
  },
  resetNewOwner({ commit }) {
    return commit('resetNewOwner')
  },
  inputChangeOwner({ commit }, owner) {
    return commit('inputChangeOwner', owner)
  },
  // state.ownerのデータをコピーして入れる
  setChangeOwner({ commit }) {
    return commit('setChangeOwnerData')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
