export default {
  en: {
    pageTitle: 'Email Confirmation',
    textClickUrl:
      'Please click on the URL in the email to complete the reservation process.',
    textCloseWindow: 'Please close this window.',
    textSentEmail:
      'We have sent an email verification to the registered email address.',
    textWarning: 'Your reservation is not confirmed yet.'
  },
  ja: {
    pageTitle: 'メール認証',
    textClickUrl:
      'メール内にあるURLをクリックし、メール認証及び{registrationType}申し込みを完了させて下さい。',
    textCloseWindow: 'こちらのブラウザは閉じて下さい。',
    textSentEmail:
      '登録されたメールアドレスにメール認証及び{registrationType}申し込みメールを送信しました。',
    textWarning: '※まだ予約は完了していません。'
  }
}
