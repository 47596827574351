import store from '@/store/store'
import axios from 'axios'
import { getApiUrl } from '@/utils/library'

export function logout() {
  store.dispatch('auth/scenarioReset')
  store.dispatch('owner/resetNewOwner')
  store.dispatch('patient/resetNewPatients')
  store.dispatch('reservation/resetNewReservation')
  store.dispatch('auth/authLogout')
  store.dispatch('owner/removeData')
  store.dispatch('patient/removeData')
  store.dispatch('reservation/resetReservations')
  store.dispatch('master/deleteMasterData')
  store.dispatch('petorelu/removeLanguageChangeAction')
}

export async function getClinicData(clinicToken) {
  try {
    const axiosObject = axios.create({
      baseURL: getApiUrl(),
      headers: {
        'Content-Type': 'application/json',
        'Net-Version': process.env.VERSION
      }
    })
    axiosObject.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 503) {
          window.location.hash = '#/maintenance'
        } else if (
          error.response?.status === 400 &&
          error.response?.data.extra === 'not the same as required version'
        ) {
          store.dispatch('petorelu/setNetVersionUpFlgToTrue')
        }
        throw error
      }
    )
    const response = await axiosObject.get('/entrance/?clinic=' + clinicToken)
    store.dispatch('clinic/addClinic', response.data.clinic)
    store.dispatch(
      'reservationSetting/addReservationSetting',
      response.data.reservationSetting
    )
    store.dispatch(
      'reservationPurpose/addReservationPurposes',
      response.data.reservationPurposes
    )
    store.dispatch('species/addSpecies', response.data.species)
    store.dispatch('auth/authEntrance', response.data.token)
    store.dispatch('auth/setBackVersion', response.data.version)
    store.dispatch('master/setData', response.data.master)
    sessionStorage.setItem('encryptedClinicId', clinicToken)
    return true
  } catch (error) {
    return false
  }
}
