<template>
  <div class="menu-box">
    <div class="menu-item" data-test="menu" @click="pushToReservationMenu">
      {{ $t('parts.menu') }}
    </div>
    <div class="menu-item" data-test="logout" @click="logout">
      {{ $t('parts.logout') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuBox',

  data() {
    return {
      ngLeavePages: [
        'OwnerPatientsInformationConfirm',
        'ReservationInputPage',
        'RegistrationConfirmChangeReservation'
      ]
    }
  },

  methods: {
    isStayNgLeavePage() {
      if (this.ngLeavePages.includes(this.$route.name)) {
        this.$store.dispatch('petorelu/ngLeave')
      }
    },
    pushToReservationMenu() {
      this.isStayNgLeavePage()
      this.$emit('click')
      this.$router.push({ name: 'ReservationMenu' }, () => {})
    },
    logout() {
      this.isStayNgLeavePage()
      this.$emit('click')
      this.$router.push({
        path: '/login-top',
        query: { clinic: sessionStorage.getItem('encryptedClinicId') }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-box {
  width: 170px;
  background: #{$plus_white};
  border: 1px solid #{$plus_orange};
  border-width: 0 0 1px 1px;
  padding: 6px 0;
  > .menu-item {
    line-height: 36px;
    font-size: 15px;
    color: #{$plus_orange};
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
