const state = {
  logined: false,
  token: '',
  entranceToken: '',
  scenario: '',
  backVersion: ''
}

const mutations = {
  login(state, token) {
    state.logined = true
    state.token = token
  },
  logout(state) {
    state.logined = false
    state.token = ''
  },
  entrance(state, entranceToken) {
    state.logined = false
    state.entranceToken = entranceToken
  },
  scenarioReset(state) {
    state.scenario = ''
  },
  changeReservationScenario(state) {
    state.scenario = 'change_reservation'
  },
  newReservationScenario(state) {
    state.scenario = 'new_reservation'
  },
  firstReservationScenario(state) {
    state.scenario = 'first_reservation'
  },
  changeOwnerPatientsScenario(state) {
    state.scenario = 'change_owner_patients'
  },
  backVersion(state, backVersion) {
    state.backVersion = backVersion
  }
}

const getters = {
  logined(state) {
    return state.logined
  },
  entranceToken(state) {
    return state.entranceToken
  },
  token(state) {
    return state.token
  },
  scenario(state) {
    return state.scenario
  },
  backVersion(state) {
    return state.backVersion
  }
}

const actions = {
  authLogin({ commit }, token) {
    return commit('login', token)
  },
  authLogout({ commit }) {
    return commit('logout')
  },
  authEntrance({ commit }, entranceToken) {
    return commit('entrance', entranceToken)
  },
  scenarioReset({ commit }) {
    return commit('scenarioReset')
  },
  changeReservationScenario({ commit }) {
    return commit('changeReservationScenario')
  },
  newReservationScenario({ commit }) {
    return commit('newReservationScenario')
  },
  firstReservationScenario({ commit }) {
    return commit('firstReservationScenario')
  },
  changeOwnerPatientsScenario({ commit }) {
    return commit('changeOwnerPatientsScenario')
  },
  setBackVersion({ commit }, backVersion) {
    return commit('backVersion', backVersion)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
