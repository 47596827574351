export default {
  en: {
    email: 'Email',
    forgetPassword: 'Password forgotten',
    forgetPassword640px: 'Password forgotten',
    forGuestButton: 'First time visitor',
    netUnavailableMessage: 'Online reservations are currently not accepted.',
    password: 'Password',
    popupMessageBadCredentials: 'Invalid email address or password',
    popupMessageNotAuthenticated:
      'The allotted time has expired. \nPlease refresh and try again.',
    popupTitle: 'Login failed'
  },
  ja: {
    email: 'メールアドレス',
    forgetPassword: 'パスワード未発行/再発行の方はこちら',
    forgetPassword640px: 'パスワード未発行\n再発行の方はこちら',
    forGuestButton: '初めて来院される方はこちら',
    netUnavailableMessage: 'ネットからの予約は現在受け付けておりません。',
    password: 'パスワード',
    popupMessageBadCredentials: 'メールアドレスもしくはパスワードが無効です',
    popupMessageNotAuthenticated:
      '規定時間が過ぎました。\n更新してからもう一度やり直してください。',
    popupTitle: '失敗'
  }
}
