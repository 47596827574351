import dedent from 'dedent'

export default {
  en: {
    errorDateTimeUnavailable: dedent`
      We apologize for the inconvenience.
      The appointment date and time you requested is already booked by someone else.
      Please submit another reservation request for a different date and time by clicking the "Make a reservation" button on the menu screen`,
    errorReservationPurposeDeleted: dedent`
      We are sorry.
      Your selected purpose of visit has been deleted.
      Please submit your reservation again by clicking the "Make a reservation" button on the menu screen.`,
    messageContact: 'If you have any questions, please call us.',
    messageReservationComplete: 'Your reservation has been confirmed.',
    messageReservationIncomplete: dedent`
      Your reservation is not confirmed yet.
      You will receive an email once your reservation has been confirmed.`,
    messageReservationRequestComplete: 'Your request has been received.',
    messageSentMail:
      'We have sent a confirmation email to the registered email address.',
    messageStatus:
      'You can also check the status of your reservation in the menu.',
    messageStatusRequest:
      'You can check the status of your request in the “Current reservations” menu.',
    pageTitle: 'Reservation Confirmed',
    pageTitleRequest: 'Reservation Request Completed',
    titleRegistrationError: 'A problem occurred when making a reservation.',
    titleVerified: 'Email verification has already been completed.'
  },
  ja: {
    errorDateTimeUnavailable: dedent`
      申し訳ございません。
      お客様がご希望された予約日時はすでに他の方の予約で埋まっています。
      メニュー画面の「予約の登録」ボタンから、もう一度別の日時で予約の申し込みをお願いします。`,
    errorReservationPurposeDeleted: dedent`
      申し訳ございません。
      お客様が選択された来院目的はすでに削除されています。
      メニュー画面の「予約の登録」ボタンから再度ご予約の申し込みをお願いします。`,
    messageContact: 'ご不明な点がございましたら、電話にてお問い合わせ下さい。',
    messageReservationComplete: '予約登録が完了しました。',
    messageReservationIncomplete: dedent`
      なお、予約登録は完了しておりません。
      予約登録が完了した際に、メールにてご連絡差し上げますので今しばらくお待ち下さい。`,
    messageReservationRequestComplete: '予約申請が完了しました。',
    messageSentMail:
      '登録したメールアドレスに予約登録完了のメールをお送りいたしました。',
    messageStatus:
      'また、メニューの「予約の確認」から予約登録の状況が確認できます。',
    messageStatusRequest:
      'また、メニューの「予約の確認」から予約の状況が確認できます。',
    pageTitle: '予約登録完了',
    pageTitleRequest: '予約申請完了',
    titleRegistrationError: '予約登録で問題が発生しました。',
    titleVerified: '既にメール認証が完了しています。'
  }
}
