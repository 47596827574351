<template>
  <div class="notification-list-item-content">
    <div class="notification-list-item-content-text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationListContentTitle'
}
</script>

<style lang="scss" scoped>
.notification-list-item-content {
  > .notification-list-item-content-text {
    text-align: left;
    color: #{$plus_black};
    margin-top: 10px;
    @include font-size(18);
    white-space: pre-wrap;
  }
}
</style>
