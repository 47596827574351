<template>
  <div class="base-text-input-password">
    <input
      class="base-text-input-password-class"
      type="password"
      name="password"
      maxlength="100"
      :placeholder="$t('common.placeholderPassword')"
      :autocomplete="newFlg ? 'new-password' : 'on'"
      :value="value"
      @input="inputText"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseTextInputPassword',

  props: {
    value: { type: String },
    newFlg: { type: Boolean, default: false }
  },

  methods: {
    inputText(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-text-input-password {
  > .base-text-input-password-class {
    @include font-size(18);
    height: 45px;
    border-radius: 5px;
    border: solid 2px #{$plus_bc_gray};
    background-color: #{$plus_white};
    padding: 2px 11px;
    box-sizing: border-box;
    &::placeholder {
      text-align: left;
      color: #{$plus_be_gray};
    }
  }
  @include selector-width('.base-text-input-password-class', 410px);
}
</style>
