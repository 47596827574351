<template>
  <div class="base-login-button">
    <button
      class="login-button"
      type="button"
      @click="handleClick"
      :disabled="disabled"
    >
      <p>{{ $t('parts.login') }}</p>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseLoginButton',

  props: {
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleClick(ev) {
      this.$emit('click', ev)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-login-button {
  > .login-button {
    height: 70px;
    border-radius: 6px;
    background-color: #ef6c00;
    border: none;
    color: #{$plus_white};
    &:disabled {
      pointer-events: none;
      background-color: #{$plus_f7_gray};
      > p {
        color: #{$plus_be_gray};
      }
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 68px;
    }
    cursor: pointer;
    > p {
      height: 29px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: #{$plus_white};
      margin: auto;
    }
  }
  @include selector-width('button', 410px);
}
</style>
