<template>
  <div :class="classes">
    <div
      class="progress-bar-area-content"
      v-for="(text, index) in textList"
      :key="index"
    >
      <div class="arrow-text">
        <div class="arrow" :class="{ orange: index < textOrangeNumber }" />
      </div>
      <progress-bar-list-text :class="{ orange: index < textOrangeNumber }">
        {{ $t(text) }}
      </progress-bar-list-text>
    </div>
  </div>
</template>
<script>
import ProgressBarListText from '@/components/parts/atoms/ProgressBarListText.vue'
export default {
  name: 'ProgressBarList',

  components: {
    ProgressBarListText
  },

  props: {
    textList: {
      type: Array
    },
    barWidthPc: {
      type: String
    },
    textOrangeNumber: {
      type: Number
    }
  },

  computed: {
    classes() {
      // 各画面のプログレスバーの全体のwidthの値を設定
      if (this.barWidthPc === 'new-user-reservation-screen') {
        return ['progress-bar-list new-user-reservation']
      }
      if (this.barWidthPc === 'login-user-reservation-screen') {
        return ['progress-bar-list login-user-reservation']
      }
      if (this.barWidthPc === 'password-resetting-screen') {
        return ['progress-bar-list password-resetting']
      }
      if (this.barWidthPc === 'owner-info-edit-screen') {
        return ['progress-bar-list owner-info-edit']
      }
      return ['progress-bar-list']
    }
  }
}
</script>
<style lang="scss" scoped>
.progress-bar-list {
  min-height: 45px;
  width: fit-content;
  height: fit-content;
  border: solid 2px #{$plus_orange};
  border-radius: 30px;
  line-height: 39px;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  padding: 0 10px;
  &.new-user-reservation {
    width: fit-content;
    min-width: 900px;
  }
  &.login-user-reservation {
    width: fit-content;
    min-width: 786px;
  }
  &.password-resetting {
    width: fit-content;
    min-width: 630px;
  }
  &.owner-info-edit {
    width: fit-content;
    min-width: 423px;
  }
  > .progress-bar-area-content {
    display: inline-block;
    &:first-child {
      > .arrow-text {
        display: none;
      }
    }
    > .arrow-text {
      display: inline-block;
      margin-left: 25px;
      margin-right: 25px;
      > .arrow {
        width: 8px;
        height: 8px;
        border-top: 2px solid #{$plus_be_gray};
        border-right: 2px solid #{$plus_be_gray};
        transform: rotate(45deg);
        &.orange {
          border-top: 2px solid #{$plus_orange};
          border-right: 2px solid #{$plus_orange};
        }
      }
    }
  }
}
$bar-width: 440px;
@media (min-width: 450px) and (max-width: 960px) {
  .progress-bar-list {
    max-width: calc-percent($bar-width, 450px);
    width: fit-content;
    > .progress-bar-area-content {
      > .arrow-text {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
@media (max-width: 450px) {
  .progress-bar-list {
    max-width: calc-percent($bar-width, 450px);
    width: fit-content;
    line-height: 37px;
    > .progress-bar-area-content {
      > .arrow-text {
        margin-left: 0px;
        margin-right: 4px;
      }
    }
  }
}
</style>
