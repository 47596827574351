<template>
  <div class="progress-bar">
    <div class="progress-bar-area" v-if="pc">
      <progress-bar-list
        :text-list="textList"
        :bar-width-pc="barWidthPc"
        :text-orange-number="textOrangeNumber"
      />
    </div>
    <div class="progress-bar-area-sp" v-if="!pc">
      <div
        class="progress-bar-area-wrapper-sp"
        v-for="(item, index) in spTextList"
        :key="index"
      >
        <progress-bar-list
          :text-list="item"
          :text-orange-number="rowOrangeNumbers.get(index)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBarList from '@/components/parts/molecules/ProgressBarList.vue'
export default {
  name: 'ProgressBar',

  components: {
    ProgressBarList
  },

  props: {
    textList: {
      type: Array
    },
    barWidthPc: {
      type: String
    },
    textOrangeNumber: {
      type: Number
    }
  },

  data() {
    return {
      pc: true,
      originalList: [],
      spTextList: []
    }
  },

  computed: {
    // 2行目のプログレスバーのテキストの色をオレンジにする数
    rowOrangeNumbers() {
      const orangeNumberRowMap = new Map()
      let stepCount = this.textOrangeNumber
      for (let i = 0; i < this.spTextList.length; i++) {
        if (stepCount <= this.spTextList[i].length) {
          orangeNumberRowMap.set(i, stepCount)
          break
        } else {
          stepCount -= this.spTextList[i].length
          orangeNumberRowMap.set(i, this.spTextList[i].length)
        }
      }
      return orangeNumberRowMap
    },
    displayLanguage() {
      return this.$i18n.locale
    }
  },
  watch: {
    displayLanguage(newVal) {
      this.handleResize()
      this.originalList = Array.from(this.textList)
      this.spTextList = []
      if (newVal === 'en') this.divideArrayEn(Array.from(this.originalList))
      if (newVal === 'ja') this.divideArray(Array.from(this.originalList))
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.originalList = Array.from(this.textList)
    if (this.displayLanguage === 'ja') {
      this.divideArray(Array.from(this.originalList))
    } else {
      this.divideArrayEn(Array.from(this.originalList))
    }
    this.handleResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      if (this.displayLanguage === 'ja') {
        if (window.innerWidth <= 960) {
          this.pc = false
        } else {
          this.pc = true
        }
      } else {
        if (window.innerWidth <= 1200) {
          this.pc = false
        } else {
          this.pc = true
        }
      }
    },
    divideArray(items) {
      // プログレスバーのテキストが3つ以下であれば、テキストは分割しない
      if (items.length > 3) {
        const value = Math.floor(items.length / 2)
        this.spTextList.push(items.splice(0, value))
        this.spTextList.push(items)
      } else {
        this.spTextList.push(items)
      }
    },
    divideArrayEn(items) {
      if (items.length === 4) {
        return this.spTextList.push([items[0], items[1]], [items[2], items[3]])
      }
      if (items.length <= 3) return this.spTextList.push(items)
      this.spTextList.push(items.splice(0, 3))
      return this.divideArrayEn(items)
    }
  }
}
</script>
<style lang="scss" scoped>
.progress-bar {
  > .progress-bar-area-sp {
    :first-child {
      margin-top: 0 !important;
    }
    > .progress-bar-area-wrapper-sp {
      margin-top: 9px;
    }
  }
}
</style>
