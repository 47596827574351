const state = { reservationRequests: [] }

const mutations = {
  setData(state, reservationRequests) {
    state.reservationRequests = reservationRequests
  }
}

const getters = {
  getData(state) {
    return state.reservationRequests
  }
}

const actions = {
  setData({ commit }, reservationRequests) {
    return commit('setData', reservationRequests)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
