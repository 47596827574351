<template>
  <div class="progress-bar-list-text">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ProgressBarListText'
}
</script>

<style lang="scss" scoped>
.progress-bar-list-text {
  display: inline;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  color: #{$plus_be_gray};
  &.orange {
    color: #{$plus_orange};
  }
}
@media (min-width: 400px) and (max-width: 640px) {
  .progress-bar-list-text {
    font-size: 13px;
  }
}
@media (max-width: 400px) {
  .progress-bar-list-text {
    font-size: 10px;
  }
}
</style>
