import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { SESSION_STORAGE_KEY } from '@/utils/define'

import auth from './modules/auth'
import clinic from './modules/clinic'
import master from './modules/master'
import owner from './modules/owner'
import patient from './modules/patient'
import petorelu from './modules/petorelu'
import reservation from './modules/reservation'
import reservationPurpose from './modules/reservation_purpose'
import reservationRequests from './modules/reservation_requests'
import reservationSetting from './modules/reservation_setting'
import species from './modules/species'

Vue.use(Vuex)
Vue.config.productionTip = false
if (process.env.NODE_ENV === 'test') Vue.config.devtools = false

export default new Vuex.Store({
  modules: {
    auth,
    clinic,
    master,
    owner,
    patient,
    petorelu,
    reservation,
    reservationPurpose,
    reservationRequests,
    reservationSetting,
    species
  },
  plugins: [
    createPersistedState({
      key: SESSION_STORAGE_KEY,
      paths: ['auth.token'],
      storage: window.sessionStorage
    })
  ]
})
