const state = {
  preventLeaveFlg: false,
  leaveDialogShow: false,
  nextRoute: null,
  invalidFlg: false,
  netVersionUpFlg: false,
  isLanguageChanged: false,
  globalPopup: {
    show: false,
    type: '',
    title: '',
    layerNumber: 1,
    buttons: [],
    message: '',
    close: () => {},
    cancel: () => {},
    decision: () => {}
  }
}

const mutations = {
  recordLanguageChangeAction(state) {
    state.isLanguageChanged = true
  },
  removeLanguageChangeAction(state) {
    state.isLanguageChanged = false
  },
  ngLeave(state) {
    state.preventLeaveFlg = true
  },
  okLeave(state) {
    state.preventLeaveFlg = false
  },
  showLeaveDialog(state) {
    state.leaveDialogShow = true
  },
  hideLeaveDialog(state) {
    state.leaveDialogShow = false
  },
  saveNextRoute(state, nextRoute) {
    state.nextRoute = nextRoute
  },
  leaveReset(state) {
    state.preventLeaveFlg = false
    state.leaveDialogShow = false
    state.nextRoute = null
  },
  invalidFlg(state, flg) {
    state.invalidFlg = flg
  },
  setNetVersionUpFlgToTrue(state) {
    state.netVersionUpFlg = true
  },
  setNetVersionUpFlgToFalse(state) {
    state.netVersionUpFlg = false
  },
  showGlobalPopup(state, popup) {
    const emptyFunction = () => {}
    state.globalPopup = {
      show: true,
      type: popup.type || '',
      title: popup.title || '',
      buttons: popup.buttons || [],
      layerNumber: popup.layerNumber || 1,
      message: popup.message || '',
      close: popup.close || emptyFunction,
      cancel: popup.cancel || emptyFunction,
      decision: popup.decision || emptyFunction
    }
  },
  hideGlobalPopup(state) {
    state.globalPopup = {
      show: false,
      type: '',
      title: '',
      buttons: [],
      layerNumber: 1,
      message: '',
      close: () => {},
      cancel: () => {},
      decision: () => {}
    }
  }
}

const getters = {
  get(state) {
    return state
  }
}

const actions = {
  recordLanguageChangeAction({ commit }) {
    return commit('recordLanguageChangeAction')
  },
  removeLanguageChangeAction({ commit }) {
    return commit('removeLanguageChangeAction')
  },
  okLeave({ commit }) {
    return commit('okLeave')
  },
  ngLeave({ commit }) {
    return commit('ngLeave')
  },
  showLeaveDialog({ commit }) {
    return commit('showLeaveDialog')
  },
  hideLeaveDialog({ commit }) {
    return commit('hideLeaveDialog')
  },
  saveNextRoute({ commit }, nextRoute) {
    return commit('saveNextRoute', nextRoute)
  },
  leaveReset({ commit }) {
    return commit('leaveReset')
  },
  invalidFlg({ commit }, flg) {
    return commit('invalidFlg', flg)
  },
  setNetVersionUpFlgToTrue({ commit }) {
    return commit('setNetVersionUpFlgToTrue')
  },
  setNetVersionUpFlgToFalse({ commit }) {
    return commit('setNetVersionUpFlgToFalse')
  },
  showGlobalPopup({ commit }, popup) {
    return commit('showGlobalPopup', popup)
  },
  hideGlobalPopup({ commit }) {
    return commit('hideGlobalPopup')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
